//=============================
//=== family ===
//=============================

.family {
  position:relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // background: url("../assets/family/family-background.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // animation:familyBg 7s .1s ease-out infinite;
  // -webkit-animation:familyBg 7s .1s ease-out infinite;
  &:before {
    content:'';
    position:absolute;
    z-index: -1;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: url("../assets/family/family-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    animation:familyBg 5.5s .6s ease-out infinite;
    -webkit-animation:familyBg 5.5s .6s ease-out infinite;
    overflow: hidden;
  }
}

@keyframes familyBg {
  0% {
      transform:scale(1);             /*  default */
      -webkit-transform:scale(1);     /*  크롬 */
      -moz-transform:scale(1);       /* FireFox */
      -o-transform:scale(1);           /* Opera */
  }
  100% {
      transform:scale(1.1);             /*  default */
      -webkit-transform:scale(1.1);     /*  크롬 */
      -moz-transform:scale(1.1);       /* FireFox */
      -o-transform:scale(1.1);           /* Opera */
  }
}
@-webkit-keyframes familyBg {
  0% {
      transform:scale(1);             /*  default */
      -webkit-transform:scale(1);     /*  크롬 */
      -moz-transform:scale(1);       /* FireFox */
      -o-transform:scale(1);           /* Opera */
  }
  100% {
      transform:scale(1.1);             /*  default */
      -webkit-transform:scale(1.1);     /*  크롬 */
      -moz-transform:scale(1.1);       /* FireFox */
      -o-transform:scale(1.1);           /* Opera */
  }
}
.family-sub-el-wide {
  background: url("../assets/family/wide.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.family-sub-el-media {
  background: url("../assets/family/media.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.family-sub-el-park {
  background: url("../assets/family/park.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.family-sub-el-rise {
  background: url("../assets/family/rise.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.family-sub-el-tv {
  background: url("../assets/family/tv.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.family-sub-el-wide,
.family-sub-el-media,
.family-sub-el-park,
.family-sub-el-rise,
.family-sub-el-tv {
  width: 100%;
  height: 100vh;
  position: relative;

  .text-wrap {
    padding-top: calc(50vh - 146px);
    box-sizing: border-box;

    .title-1 {
      width: 115px;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #FFFFFF;
      border-radius: 55px;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      margin-bottom: 27px;
      text-align: center;
      line-height: 40px;
    }

    .title-2 {
      font-family: 'Noto Sans KR', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 52px;
      line-height: 100%;
      letter-spacing: 0.05em;
      color: #FFFFFF;
      margin-bottom: 100px;

      & > span {
        color: #00CCB3;
      }
    }

    .description {
      font-family: 'Noto Sans KR', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      color: #FFFFFF;
    }
  }
}

.family-sub-el-wide,
.family-sub-el-park,
.family-sub-el-tv {

  .text-wrap {
    padding-left: 25vw;

    .title-2 {

      & > span {
        color: #00CCB3;
      }
    }
  }
}

.family-sub-el-media,
.family-sub-el-rise {

  .text-wrap {
    padding-right: 25vw;
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: right;

    .title-2 {

      & > span {
        color: #FFEB38;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .family {
    width: 100%;
    height: 100vh;
    background: url("../assets/family/family-background-mo.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .family-sub-el-wide,
  .family-sub-el-media,
  .family-sub-el-park,
  .family-sub-el-rise,
  .family-sub-el-tv {
    width: 100%;
    height: 100vh;
    position: relative;

    .text-wrap {
      padding-top: calc(50vh - 146px);
      box-sizing: border-box;

      .title-1 {
        width: 89px;
        height: 32px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #FFFFFF;
        border-radius: 55px;
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 27px;
        text-align: center;
        line-height: 32px;
      }

      .title-2 {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 100%;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        margin-bottom: 40px;

        & > span {
          color: #00CCB3;
        }
      }

      .description {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #FFFFFF;
      }
    }
  }

  .family-sub-el-wide,
  .family-sub-el-park,
  .family-sub-el-tv {

    .text-wrap {
      padding-left: 25px;
      padding-right: 50px;

      .title-2 {

        & > span {
          color: #00CCB3;
        }
      }
    }
  }

  .family-sub-el-media,
  .family-sub-el-rise {

    .text-wrap {
      padding-left: 20px;
      padding-right: 50px;
      display: flex;
      flex-direction: column;
      align-items: end;
      text-align: right;

      .title-2 {

        & > span {
          color: #FFEB38;
        }
      }
    }
  }
}




////=============================
////=== family ===
////=============================
//
//.family {
//
//  .slick-dots {
//    width: 100px;
//    margin: 1.7em;
//    left: 168px;
//    top: 50%;
//    transform: translate(0%, calc(-50% + 100px));
//
//    & > li {
//      display: block;
//    }
//
//    & > li button {
//      margin: 5px 0px;
//    }
//
//    & > li button:before {
//      color: white;
//    }
//
//    & > li.slick-active button:before {
//      color: white;
//      padding: 0px;
//      border: 1px solid white;
//      border-radius: 50%;
//      box-sizing: border-box;
//    }
//  }
//
//  .slick-vertical .slick-slide {
//    border: 0px;
//  }
//
//  .section {
//    width: 100%;
//    height: 100vh;
//    box-sizing: border-box;
//    position: relative;
//
//    .background-video {
//      position: absolute;
//      top: 0px;
//      right: 0;
//      bottom: 0;
//      left: 0;
//      width: 100%;
//      height: 100%;
//      overflow: hidden;
//
//      video {
//        object-fit: cover;
//        width: 100vw;
//        height: 100%;
//      }
//    }
//
//    .overlay {
//      position: absolute;
//      top: 0px;
//      right: 0;
//      bottom: 0;
//      left: 0;
//      width: 100%;
//      height: 100%;
//      background-color: black;
//      opacity: 0.25;
//      z-index: 1;
//    }
//
//    .text-wrap {
//      position: relative;
//      z-index: 2;
//    }
//  }
//
//  .section-1 {
//    position: relative;
//    box-sizing: border-box;
//
//    .text-wrap {
//      text-align: center;
//      box-sizing: border-box;
//      padding-top: 23vh;
//
//      .title {
//        font-family: "Titillium Web";
//        color: #263781;
//        font-weight: 600;
//        font-size: 60px;
//        line-height: 91px;
//        margin-bottom: 103px;
//      }
//
//      .square-icon {
//        width: 28px;
//        height: 28px;
//        margin: 0 auto 119px;
//      }
//
//      .description {
//        font-family: 'Noto Sans KR', sans-serif;
//        font-style: normal;
//        font-weight: 400;
//        font-size: 28px;
//        line-height: 180%;
//        color: rgba(38, 55, 129, 0.8);
//        margin-bottom: 249px;
//      }
//
//      .mouse-icon {
//        width: 25px;
//        height: 36px;
//        margin: 0 auto;
//      }
//    }
//  }
//
//  .section-2,
//  .section-3,
//  .section-4,
//  .section-5,
//  .section-6 {
//
//    .text-wrap {
//      padding-top: calc(50vh - 146px);
//      box-sizing: border-box;
//
//      .title-1 {
//        width: 115px;
//        height: 40px;
//        background: rgba(255, 255, 255, 0.1);
//        border: 1px solid #FFFFFF;
//        border-radius: 55px;
//        font-family: 'Titillium Web';
//        font-style: normal;
//        font-weight: 400;
//        font-size: 20px;
//        line-height: 100%;
//        color: #FFFFFF;
//        margin-bottom: 27px;
//        text-align: center;
//        line-height: 40px;
//      }
//
//      .title-2 {
//        font-family: 'Noto Sans KR', sans-serif;
//        font-style: normal;
//        font-weight: 600;
//        font-size: 52px;
//        line-height: 100%;
//        letter-spacing: 0.05em;
//        color: #FFFFFF;
//        margin-bottom: 100px;
//
//        & > span {
//          color: #00CCB3;
//        }
//      }
//
//      .description {
//        font-family: 'Noto Sans KR', sans-serif;
//        font-style: normal;
//        font-weight: 400;
//        font-size: 24px;
//        line-height: 150%;
//        color: #FFFFFF;
//      }
//    }
//  }
//
//  .section-2,
//  .section-4,
//  .section-6 {
//
//    .text-wrap {
//      padding-left: 25vw;
//
//      .title-2 {
//
//        & > span {
//          color: #00CCB3;
//        }
//      }
//    }
//  }
//
//  .section-3,
//  .section-5 {
//
//    .text-wrap {
//      padding-right: 25vw;
//      display: flex;
//      flex-direction: column;
//      align-items: end;
//      text-align: right;
//
//      .title-2 {
//
//        & > span {
//          color: #FFEB38;
//        }
//      }
//    }
//  }
//}
