//=============================
//=== Brands ===
//=============================


.brands {
  height: 100vh;

  .slick-vertical .slick-slide {
    border: 0px;
  }

  .vertical-section {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      opacity: 0.3;
      z-index: 1;
    }

    & > .text-box-black {
      color: #333333;
    }

    & > .text-box-white {
      color: #FFFFFF;
    }
  }

  .vertical-section-1 {
    position: relative;
    box-sizing: border-box;
    padding-top: 25vh;

    .background-video {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0px;
      width: 100%;
      height: 100%;
      overflow: hidden;

      video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0px;
      }
    }
  }

  .vertical-section-2 {
    position:relative;
    
    .horizontal-section {
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      .text-wrap {
        position: absolute;
        top: 35vh;
        left: 22vw;
        box-sizing: border-box;

        .text-1 {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 30px;
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          text-align: center;
          object-fit: cover;
          display: inline-block;
          box-sizing: border-box;
          padding: 5px 15px;
          color: #FFFFFF;
        }

        .text-2 {
          margin-top: 38px;
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 100%;
          align-items: center;
        }

        .text-3 {
          margin-top: 42px;
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 150%;
        }

        .more-button {
          width: 163px;
          height: 48px;
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 70px;
          cursor: pointer;
        }
      }

      .text-wrap.white {
        color: #FFFFFF;

        .more-button {
          background-image: url("../assets/brands/brands-more-button-white.png");
        }
      }

      .text-wrap.black {
        color: #333333;

        .more-button {
          background-image: url("../assets/brands/brands-more-button-black.png");
        }
      }

      // .slide-button-wrap {
      //   bottom: 10vh;
      //   right: 10vw;
      //   position: absolute;
      //   display: flex;
      //   //top: 50%;
      //   //right: 50px;
      //   //position: absolute;
      //   //display: flex;
      //   //flex-direction: column;
      //   //transform: translate(0%, -50%);

      //   .slide-button {
      //     width: 120px;
      //     height: 90px;
      //     border-radius: 5px;
      //     background-size: cover;
      //     background-repeat: no-repeat;
      //     background-position: center;
      //     cursor: pointer;
      //     //margin: 10px 0px;
      //     margin: 0px 10px;
      //     filter: grayscale(100%);
      //   }

      //   .slide-button.color {
      //     filter: grayscale(0%);
      //     box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
      //     border: 1px solid #FFFFFF;
      //   }

      //   .slide-button-1 {
      //     background-image: url("../assets/brands/brands-slide-button-1.jpg");
      //   }

      //   .slide-button-2 {
      //     background-image: url("../assets/brands/brands-slide-button-2.jpg");
      //   }

      //   .slide-button-3 {
      //     background-image: url("../assets/brands/brands-slide-button-3.jpg");
      //   }

      //   .slide-button-4 {
      //     background-image: url("../assets/brands/brands-slide-button-4.jpg");
      //   }

      //   .slide-button-5 {
      //     background-image: url("../assets/brands/brands-slide-button-5.jpg");
      //   }
      // }
    }

    .horizontal-section-1 {
      background-image: url("../assets/brands/brands-item-background-1.jpg");
    }

    .horizontal-section-2 {
      background-image: url("../assets/brands/brands-item-background-2.jpg");
    }

    .horizontal-section-3 {
      background-image: url("../assets/brands/brands-item-background-3.jpg");
    }

    .horizontal-section-4 {
      background-image: url("../assets/brands/brands-item-background-4.jpg");
    }

    .horizontal-section-5 {
      background-image: url("../assets/brands/brands-item-background-5.jpg");
    }
    
    .slide-button-wrap {
      bottom: 10vh;
      right: 10vw;
      position: absolute;
      display: flex;
      //top: 50%;
      //right: 50px;
      //position: absolute;
      //display: flex;
      //flex-direction: column;
      //transform: translate(0%, -50%);

      .slide-button {
        width: 120px;
        height: 90px;
        border-radius: 5px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        //margin: 10px 0px;
        margin: 0px 10px;
        filter: grayscale(100%);
      }

      .slide-button.color {
        filter: grayscale(0%);
        box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
        border: 1px solid #FFFFFF;
      }

      .slide-button-1 {
        background-image: url("../assets/brands/brands-slide-button-1.jpg");
      }

      .slide-button-2 {
        background-image: url("../assets/brands/brands-slide-button-2.jpg");
      }

      .slide-button-3 {
        background-image: url("../assets/brands/brands-slide-button-3.jpg");
      }

      .slide-button-4 {
        background-image: url("../assets/brands/brands-slide-button-4.jpg");
      }

      .slide-button-5 {
        background-image: url("../assets/brands/brands-slide-button-5.jpg");
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .brands {
    height: 100vh;

    .slick-vertical .slick-slide {
      border: 0px;
    }

    .vertical-section {
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .overlay {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: black;
        opacity: 0.3;
        z-index: 1;
      }

      & > .text-box-black {
        color: #333333;
      }

      & > .text-box-white {
        color: #FFFFFF;
      }
    }

    .vertical-section-1 {
      position: relative;
      box-sizing: border-box;
      padding-top: 25vh;

      .background-video {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;

        video {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
          position: fixed;
          left: 0;
          top: 0px;
        }
      }
    }

    .vertical-section-2 {

      .horizontal-section {
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .text-wrap {
          position: absolute;
          top: 25vh;
          left: 20px;
          box-sizing: border-box;

          .text-1 {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 30px;
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            text-align: center;
            object-fit: cover;
            display: inline-block;
            box-sizing: border-box;
            padding: 5px 15px;
            color: #FFFFFF;
          }

          .text-2 {
            margin-top: 28px;
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 100%;
            align-items: center;
          }

          .text-3 {
            margin-top: 28px;
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
          }

          .more-button {
            width: 163px;
            height: 48px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            margin-top: 70px;
            cursor: pointer;
          }
        }

        .text-wrap.white {
          color: #FFFFFF;

          .more-button {
            background-image: url("../assets/brands/brands-more-button-white.png");
          }
        }

        .text-wrap.black {
          color: #333333;

          .more-button {
            background-image: url("../assets/brands/brands-more-button-black.png");
          }
        }

        // .slide-button-wrap {
        //   bottom: 80px;
        //   position: absolute;
        //   display: flex;
        //   margin-left: auto;
        //   margin-right: auto;
        //   left: 0;
        //   right: 0;
        //   text-align: center;
        //   justify-content: center;

        //   //top: 50%;
        //   //right: 50px;
        //   //position: absolute;
        //   //display: flex;
        //   //flex-direction: column;
        //   //transform: translate(0%, -50%);

        //   .slide-button {
        //     width: 60px;
        //     height: 45px;
        //     border-radius: 5px;
        //     background-size: cover;
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     cursor: pointer;
        //     //margin: 10px 0px;
        //     margin: 0px 4px;
        //     filter: grayscale(100%);
        //   }

        //   .slide-button.color {
        //     filter: grayscale(0%);
        //     box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
        //     border: 1px solid #FFFFFF;
        //   }

        //   .slide-button-1 {
        //     background-image: url("../assets/brands/brands-slide-button-1.jpg");
        //   }

        //   .slide-button-2 {
        //     background-image: url("../assets/brands/brands-slide-button-2.jpg");
        //   }

        //   .slide-button-3 {
        //     background-image: url("../assets/brands/brands-slide-button-3.jpg");
        //   }

        //   .slide-button-4 {
        //     background-image: url("../assets/brands/brands-slide-button-4.jpg");
        //   }

        //   .slide-button-5 {
        //     background-image: url("../assets/brands/brands-slide-button-5.jpg");
        //   }
        // }
      }

      .horizontal-section-1 {
        background-image: url("../assets/brands/brands-item-background-1.jpg");
      }

      .horizontal-section-2 {
        background-image: url("../assets/brands/brands-item-background-2.jpg");
        background-position:calc(50% - 180px) 50%;
      }

      .horizontal-section-3 {
        background-image: url("../assets/brands/brands-item-background-3.jpg");
        background-position:calc(50% - 180px) 50%;
      }

      .horizontal-section-4 {
        background-image: url("../assets/brands/brands-item-background-4.jpg");
      }

      .horizontal-section-5 {
        background-image: url("../assets/brands/brands-item-background-5.jpg");
        background-position:calc(50% - 180px) 50%;
      }
      .slide-button-wrap {
        bottom: 80px;
        position: absolute;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        justify-content: center;

        //top: 50%;
        //right: 50px;
        //position: absolute;
        //display: flex;
        //flex-direction: column;
        //transform: translate(0%, -50%);

        .slide-button {
          width: 60px;
          height: 45px;
          border-radius: 5px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          //margin: 10px 0px;
          margin: 0px 4px;
          filter: grayscale(100%);
        }

        .slide-button.color {
          filter: grayscale(0%);
          box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.25);
          border: 1px solid #FFFFFF;
        }

        .slide-button-1 {
          background-image: url("../assets/brands/brands-slide-button-1.jpg");
        }

        .slide-button-2 {
          background-image: url("../assets/brands/brands-slide-button-2.jpg");
        }

        .slide-button-3 {
          background-image: url("../assets/brands/brands-slide-button-3.jpg");
        }

        .slide-button-4 {
          background-image: url("../assets/brands/brands-slide-button-4.jpg");
        }

        .slide-button-5 {
          background-image: url("../assets/brands/brands-slide-button-5.jpg");
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .brands {
    .vertical-section-2 {
      .horizontal-section-2 {
        background-position:calc(50% - 200px) 50%;
      }
      
      .horizontal-section-4 {
        background-image: url("../assets/brands/brands-item-background-4-mobile.jpg");
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .brands {
    .vertical-section-2 {
      .horizontal-section-2 {
        background-position:calc(50% - 100px) 50%;
      }
      .horizontal-section-5 {
        background-position:calc(50% - 100px) 50%;
      }
    }
  }
}
@media screen and (max-width: 460px) {
  .brands {
    .vertical-section-2 {
      .horizontal-section-2 {
        background-position:calc(50% - 50px) 50%;
      }
    }
  }
}