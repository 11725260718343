.notice {
  position: relative;
  padding-bottom: 120px;
  box-sizing: border-box;

  .section-1 {
    width: 100%;
    height: 100vh;
    background: url("../assets/images/notice-section-1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-attachment: fixed;
  }

  .section-2 {
    max-width: 1320px;
    width: calc(100% - 136px);
    margin: -120px auto 0px;
    z-index: 2;
    position: relative;

    .section-title {
      width: 100%;
      max-width: 1320px;
      height: 228px;
      background: url("../assets/images/notice-setction-2-title-background.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin: 0px auto 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 80px 0px 125px;
      box-sizing: border-box;
      z-index: 1;

      .section-title-sub-wrap-1 {
        display: flex;
        align-items: center;

        p:first-child {
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 700;
          font-size: 54px;
          line-height: 100%;
          color: #333333;
        }

        .sub-section {
          display: flex;
          align-items: center;

          .divider {
            width: 1px;
            height: 36px;
            background: #333333;
            margin: 0px 30px;
          }

          p:last-child {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 180%;
            color: #333333;
          }
        }
      }

      .section-title-sub-wrap-2 {
        width: 120px;
        height: 120px;
        background: #FFFFFF;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 120%;
          text-align: center;
          color: #3FD6F5;
        }

        .count {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 120%;
          text-align: center;
          color: #3FD6F5;
        }
      }
    }
  }

  .section-3 {
    max-width: 1320px;
    width: calc(100% - 136px);
    min-height: 700px;
    position: relative;
    margin: 0 auto;

    .notice-list {
      width: 100%;
      border-top: 2px solid #000000;

      .empty-message {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 180%;
        text-align: center;
        color: #333333;
        margin-top: 120px;
      }

      a {
        text-decoration: none;

        .notice-wrap {
          width: 100%;
          min-height: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 60px;
          box-sizing: border-box;
          cursor: pointer;
          border-bottom: 1px solid #BCBFC0;

          &.notice-fixed {
            .arrow-icon {
              display: none;
            }
            .notice-pin {
              display: block;
            }
          }

          & > div {
            display: flex;
            align-items: center;

            .arrow-icon {
              width: 40px;
              height: 40px;
              margin-right: 30px;
              visibility: hidden;
            }
            .notice-pin {
              width: 30px;
              height: 31px;
              margin-right: 30px;
              margin-left:10px;
              display: none;
            }

            .title {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 150%;
              color: #333333;
            }
          }

          .date {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            color: #333333;
          }
        }

        .notice-wrap:hover {
          .arrow-icon {
            visibility: visible;
            img {
              animation:iconMove .8s ease-out infinite;
              -webkit-animation:iconMove .8s ease-out infinite;
            }
            @keyframes iconMove {
              0% {margin-left:5px}
              50% {margin-left:-5px}
              100% {margin-left:5px;}
            }
            @-webkit-keyframes iconMove {
              0% {margin-left:5px}
              50% {margin-left:-5px}
              100% {margin-left:5px;}
            }
          }
        }
      }
    }

    .more-button {
      width: 163px;
      height: 48px;
      cursor: pointer;
      margin: 120px auto 0px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .notice {
    position: relative;
    padding-bottom: 80px;
    box-sizing: border-box;

    .section-1 {
      width: 100%;
      height: 100vh;
      background: url("../assets/images/notice-section-1-background-mo.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
    }

    .section-2 {
      max-width: initial;
      width: 100%;
      margin: -120px auto 0px;
      z-index: 2;
      padding: 0px 10px;
      box-sizing: border-box;
      position: relative;

      .section-title {
        width: 100%;
        max-width: initial;
        height: 121px;
        background: url("../assets/images/notice-setction-2-title-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        margin: 0px auto 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        box-sizing: border-box;
        z-index: 1;

        .section-title-sub-wrap-1 {
          display: block;
          //align-items: center;

          p:first-child {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 100%;
            color: #333333;
          }

          .sub-section {
            display: flex;
            align-items: center;

            .divider {
              width: 1px;
              height: 14px;
              background: #333333;
              margin: 0px 10px 0px 0px;
            }

            p:last-child {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 180%;
              color: #333333;
            }
          }
        }

        .section-title-sub-wrap-2 {
          width: 80px;
          height: 80px;
          background: #FFFFFF;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            text-align: center;
            color: #3FD6F5;
          }

          .count {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #3FD6F5;
          }
        }
      }
    }

    .section-3 {
      max-width: initial;
      width: 100%;
      position: relative;
      margin: 0 auto;
      padding: 0px 10px;
      box-sizing: border-box;

      .notice-list {
        width: 100%;

        .empty-message {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          text-align: center;
          color: #333333;
          margin-top: 120px;
        }

        a {
          text-decoration: none;

          .notice-wrap {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            padding: 0px 20px;
            box-sizing: border-box;
            cursor: pointer;
            border-bottom: 1px solid #BCBFC0;

            & > div {
              display: flex;
              align-items: center;

              .arrow-icon {
                width: 40px;
                height: 40px;
                margin-right: 30px;
                display: none;
              }

              .notice-pin {
                width: 22px;
                height: 22px;
                margin-right: 8px;
                margin-left:0;
                display: none;
                img {
                  width: 100%;
                }
              }

              .title {
                font-family: 'Noto Sans KR', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 150%;
                color: #333333;
              }
            }

            .date {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 150%;
              color: #333333;
              margin-top: 10px;
            }
          }

          .notice-wrap:hover {
            .arrow-icon {
              display: none;
            }
          }
        }
      }

      .more-button {
        width: 163px;
        height: 48px;
        cursor: pointer;
        margin: 60px auto 0px;
      }
    }
  }
}