//=============================
//=== Recruit ===
//=============================

.recruit {
  padding-bottom: 160px;
  box-sizing: border-box;
  position: relative;

  .section-1 {
    width: 100%;
    height: 100vh;
    background: url("../assets/images/recruit-section-1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-attachment: fixed;

    .tab-wrap {
      width: calc(100% - 136px);
      height: 131px;
      border-bottom: 2px solid #F4F4F4;
      border-top-left-radius: 26px;
      border-top-right-radius: 26px;
      position: absolute;
      background: #FFFFFF;
      left: 0px;
      right: 0px;
      bottom: 80px;
      margin: 0 auto;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;


      .tab {
        width: 95px;
        height: 130px;
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130px;
        color: #333333;
        text-align: center;
        margin: 0px 40px;
        cursor: pointer;
      }

      .tab.selected {
        color: #3FD6F5;
        border-bottom: 2px solid #3FD6F5;
      }
    }
  }

  .section-2 {
    width: calc(100% - 136px);
    min-height: 300px;
    margin: -40px auto 0px;
    z-index: 2;
    position: relative;

    .recruit-list {
      max-width: 1320px;
      width: 100%;
      box-sizing: border-box;
      margin: 0 auto;

      .empty-text {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 180%;
        text-align: center;
        color: #333333;
        margin-top: 120px;
      }

      .recruit-item {
        width: 100%;
        height: 153px;
        margin: 20px 0px;
        border: 1px solid #BCBFC0;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0px 119px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div:nth-child(1) {

          .recruit-title {
            width: 455px;
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 180%;
            color: #333333;
          }

          .recruit-date {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            color: #BCBFC0;
            margin-top: 6px;
          }
        }

        & > div:nth-child(2) {
          width: 523px;
          display: flex;
          align-items: end;
          justify-content: end;

          .job-site {
            display: flex;
            align-items: center;
            margin-right: 50px;
            cursor: pointer;
            text-decoration: none;

            .korea {
              width: 36px;
            }

            .saram {
              width: 30px;
            }

            .wanted {
              width: 42px;
            }

            .game {
              width: 30px;
            }

            img {
              width: 100%;
            }

            p {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 180%;
              text-align: center;
              color: #555555;
              margin-left: 10px;
            }
          }

          .job-site:last-child {
            margin-right: 0px;
          }

          .closed {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            text-align: center;
            color: #BCBFC0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .recruit {
    padding-bottom: 160px;
    box-sizing: border-box;
    position: relative;

    .section-1 {
      width: 100%;
      height: 100vh;
      background: url("../assets/images/recruit-section-1-background-mo.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;

      .tab-wrap {
        width: initial;
        height: auto;
        border-bottom: none;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        position: absolute;
        background: #FFFFFF;
        left: 0px;
        right: 0px;
        bottom: 60px;
        margin: 0 auto;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .tab {
          width: 90px;
          height: 52px;
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 52px;
          color: #333333;
          text-align: center;
          padding: 0px 8px;
          margin: 0px 6px;
          box-sizing: border-box;
          cursor: pointer;
          background: #F4F4F4;
          border-radius: 25px;
          margin-bottom: 8px;
        }

        .tab.selected {
          color: #FFFFFF;
          background: #3FD6F5;
          border-bottom: 0px;
        }
      }
    }

    .section-2 {
      width: 100%;
      margin: -40px auto 0px;
      z-index: 2;
      position: relative;
      padding: 0px 10px;
      box-sizing: border-box;
      min-height: initial;

      .recruit-list {
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;

        .empty-text {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          text-align: center;
          color: #333333;
          margin-top: 120px;
        }

        .recruit-item {
          width: 100%;
          height: auto;
          margin: 20px 0px;
          border: 1px solid #BCBFC0;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 24px;
          display: block;
          justify-content: space-between;
          align-items: center;

          & > div:nth-child(1) {

            .recruit-title {
              width: 100%;
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 180%;
              color: #333333;
            }

            .recruit-date {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 180%;
              color: #BCBFC0;
              margin-top: 6px;
              margin-bottom: 12px;
            }
          }

          & > div:nth-child(2) {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: start;

            .job-site {
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              margin-right: 28px;

              .korea {
                width: 36px;
                height: 33px;
                display: flex;
                align-items: center;
              }

              .saram {
                width: 30px;
                height: 33px;
                display: flex;
                align-items: center;
              }

              .wanted {
                width: 42px;
                height: 33px;
                display: flex;
                align-items: center;
              }

              .game {
                display: flex;
                align-items: center;
                width: 30px;
                height: 33px;
                margin-right: 0px;
              }

              img {
                width: 100%;
              }

              p {
                font-family: 'Noto Sans KR', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 180%;
                text-align: center;
                color: #555555;
                margin-left: 0px;
              }
            }

            .job-site:last-child {
              margin-right: 0px;
            }

            .closed {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 180%;
              text-align: center;
              color: #BCBFC0;
            }
          }
        }
      }
    }
  }
}