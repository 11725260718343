//=============================
//=== PressRelease ===
//=============================

.elnow {
  position: relative;
  padding-bottom: 80px;
  box-sizing: border-box;

  .section-1 {
    width: 100%;
    height: 100vh;
    background: url("../assets/elnow/elnow-section-1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-attachment: fixed;
  }

  .section-2 {
    max-width: 1320px;
    width: calc(100% - 136px);
    margin: -120px auto 0px;
    z-index: 2;
    position: relative;

    .section-title {
      width: 100%;
      max-width: 1320px;
      height: 228px;
      background: url("../assets/images/press-release-setction-3-title-background.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin: 0px auto 80px;
      display: flex;
      align-items: center;
      padding-left: 125px;
      box-sizing: border-box;
      z-index: 1;

      p:first-child {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 100%;
        color: #333333;
      }

      .sub-section {
        display: flex;
        align-items: center;

        .divider {
          width: 1px;
          height: 36px;
          background: #333333;
          margin: 0px 30px;
        }

        p:last-child {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 180%;
          color: #333333;
        }
      }
    }

    .image-wrap {
      max-width: 1320px;
      width: 100%;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img:hover {
        transform:scale(1.1);             /*  default */
        -webkit-transform:scale(1.1);     /*  크롬 */
        -moz-transform:scale(1.1);       /* FireFox */
        -o-transform:scale(1.1);           /* Opera */
        transition: transform 0.7s;
      }

      .not-hover:hover {
        transform:scale(1);             /*  default */
        -webkit-transform:scale(1);     /*  크롬 */
        -moz-transform:scale(1);       /* FireFox */
        -o-transform:scale(1);           /* Opera */
      }

      .type-1 {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div:last-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 876px;
        };

        .image-hover-wrap-1 {
          width: 656px;
          height: 876px;
          overflow: hidden;
        }

        .image-hover-wrap-2,
        .image-hover-wrap-3 {
          width: 656px;
          height: 434px;
          overflow: hidden;
        }
      }

      .type-2 {
        width: 100%;
        height: 880px;
        overflow: hidden;
        margin-top: 8px;
      }

      .type-3 {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        .image-hover-wrap {
          width: 656px;
          height: 434px;
          overflow: hidden;
        }
      }

      .type-4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;

        & > div:first-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 876px;
        };

        .image-hover-wrap-1,
        .image-hover-wrap-2 {
          width: 656px;
          height: 434px;
          overflow: hidden;
        }

        .image-hover-wrap-3 {
          width: 656px;
          height: 876px;
          overflow: hidden;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .elnow {
    position: relative;
    //padding-bottom: 80px;
    box-sizing: border-box;

    .section-1 {
      width: 100%;
      height: 100vh;
      background: url("../assets/images/elnow-section-1-background-mo.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
    }

    .section-2 {
      max-width: initial;
      width: 100%;
      margin: -120px auto 0px;
      z-index: 2;
      padding: 0px 10px;
      box-sizing: border-box;
      position: relative;

      .section-title {
        width: 100%;
        max-width: 1320px;
        height: 121px;
        background: url("../assets/images/press-release-setction-3-title-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        margin: 0px auto 40px;
        display: block;
        align-items: center;
        padding-left: 21px;
        box-sizing: border-box;
        z-index: 1;
        padding-top: 27px;

        p:first-child {
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 100%;
          color: #333333;
        }

        .sub-section {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .divider {
            width: 1px;
            height: 14px;
            background: #333333;
            margin: 0px 10px 0px 0px;
          }

          p:last-child {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            color: #333333;
          }
        }
      }

      .image-wrap {
        max-width: 640px;
        width: 100%;
        margin: 0 auto;
        padding: 0px;
        box-sizing: border-box;

        img {
          width: 100%;
        }

        img:hover {
          transform:scale(1);             /*  default */
          -webkit-transform:scale(1);     /*  크롬 */
          -moz-transform:scale(1);       /* FireFox */
          -o-transform:scale(1);           /* Opera */
          transition: transform 0.7s;
        }
      }
    }
  }

}