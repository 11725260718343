//=============================
//=== PressRelease ===
//=============================

.press-release {
  position: relative;
  padding-bottom: 80px;
  box-sizing: border-box;

  .section-1 {
    width: 100%;
    height: 100vh;
    background: url('../assets/images/press-release-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-attachment: fixed;
  }

  .section-2 {
    max-width: 1320px;
    width: calc(100% - 136px);
    margin: -120px auto 0px;
    z-index: 2;
    position: relative;

    .section-title {
      width: 100%;
      max-width: 1320px;
      height: 228px;
      background: url('../assets/images/press-release-setction-2-title-background.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin: 0px auto 80px;
      display: flex;
      align-items: center;
      padding-left: 125px;
      box-sizing: border-box;
      z-index: 1;

      p:first-child {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 100%;
        color: #333333;
      }

      .sub-section {
        display: flex;
        align-items: center;

        .divider {
          width: 1px;
          height: 36px;
          background: #333333;
          margin: 0px 30px;
        }

        p:last-child {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 180%;
          color: #333333;
        }
      }
    }

    .first-news-item {
      display: flex;
      margin-bottom: 80px;
      position: relative;

      .image-wrap {
        width: 720px;
        height: 492px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        img:hover {
          transform: scale(1.1); /*  default */
          -webkit-transform: scale(1.1); /*  크롬 */
          -moz-transform: scale(1.1); /* FireFox */
          -o-transform: scale(1.1); /* Opera */
          transition: transform 0.7s;
        }
      }

      .content-wrap {
        position: absolute;
        width: 630px;
        height: 433px;
        right: 0px;
        top: 30px;
        background: #000000;
        padding: 54px 50px 0px 50px;
        box-sizing: border-box;

        .title {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 150%;
          color: #ffffff;
        }

        .date {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          color: #bcbfc0;
          margin: 20px 0px;
        }

        .content {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 180%;
          color: #ffffff;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
    }

    .press-release-news {
      max-width: 1320px;
      box-sizing: border-box;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      a {
        text-decoration: none;
      }

      .news-item {
        position: relative;
        z-index: 1;
        width: 650px;
        min-height: 573px;
        cursor: pointer;
        margin-right: 0px;
        margin-bottom: 20px;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
        // border:6px solid #3fd6f5;
        &:before {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          border: 4px solid transparent;
          width: 0;
          height: 0;
        }
        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          bottom: 0;
          right: 0;
          border: 4px solid transparent;
          width: 0;
          height: 0;
        }
        &:hover {
          &:before {
            border-top-color: #3fd6f5;
            border-right-color: #3fd6f5;
            width: 100%;
            height: 100%;
            transition: width 0.1s ease-out, height 0.1s ease-out 0.1s;
            box-sizing: border-box;
          }
          &:after {
            border-bottom-color: #3fd6f5;
            border-left-color: #3fd6f5;
            width: 100%;
            height: 100%;
            transition: border-color 0s ease-out 0.2s, width 0.1s ease-out 0.2s, height 0.1s ease-out 0.3s;
            box-sizing: border-box;
          }
          .image-wrap {
            img {
              transform: scale(1.1); /*  default */
              -webkit-transform: scale(1.1); /*  크롬 */
              -moz-transform: scale(1.1); /* FireFox */
              -o-transform: scale(1.1); /* Opera */
              transition: transform 0.7s;
            }
          }
          .title {
            font-weight: bold;
          }
          .date {
            font-weight: bold;
          }
        }

        .image-wrap {
          width: 100%;
          height: 398px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          // img:hover {
          //   transform:scale(1.1);             /*  default */
          //   -webkit-transform:scale(1.1);     /*  크롬 */
          //   -moz-transform:scale(1.1);       /* FireFox */
          //   -o-transform:scale(1.1);           /* Opera */
          //   transition: transform 0.7s;
          // }
        }

        .title {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 180%;
          color: #333333;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .date {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          color: #bcbfc0;
          margin-top: 10px;
        }
      }

      .news-item:nth-child(3n) {
        margin-right: 0px;
        margin-bottom: 0px;
      }
    }

    .more-button {
      width: 163px;
      height: 48px;
      cursor: pointer;
      margin: 120px auto 160px;
    }
  }

  .section-3 {
    width: 100%;
    position: relative;
    margin: 120px auto 0px;
    background: #333333;
    padding: 120px 0px 180px;
    box-sizing: border-box;

    .section-title {
      width: 100%;
      max-width: 1320px;
      height: 228px;
      background: url('../assets/images/press-release-setction-3-title-background.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin: 0px auto 80px;
      display: flex;
      align-items: center;
      padding-left: 125px;
      box-sizing: border-box;
      z-index: 1;

      p:first-child {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 100%;
        color: #333333;
      }

      .sub-section {
        display: flex;
        align-items: center;

        .divider {
          width: 1px;
          height: 36px;
          background: #333333;
          margin: 0px 30px;
        }

        p:last-child {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 180%;
          color: #333333;
        }
      }
    }

    .image-wrap {
      max-width: 1320px;
      width: 100%;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img:hover {
        transform: scale(1.1); /*  default */
        -webkit-transform: scale(1.1); /*  크롬 */
        -moz-transform: scale(1.1); /* FireFox */
        -o-transform: scale(1.1); /* Opera */
        transition: transform 0.7s;
      }

      .not-hover:hover {
        transform: scale(1); /*  default */
        -webkit-transform: scale(1); /*  크롬 */
        -moz-transform: scale(1); /* FireFox */
        -o-transform: scale(1); /* Opera */
      }

      .type-1 {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div:last-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 876px;
        }

        .image-hover-wrap-1 {
          width: 656px;
          height: 876px;
          overflow: hidden;
        }

        .image-hover-wrap-2,
        .image-hover-wrap-3 {
          width: 656px;
          height: 434px;
          overflow: hidden;
        }
      }

      .type-2 {
        width: 100%;
        height: 880px;
        overflow: hidden;
        margin-top: 8px;
      }

      .type-3 {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        .image-hover-wrap {
          width: 656px;
          height: 434px;
          overflow: hidden;
        }
      }

      .type-4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;

        & > div:first-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 876px;
        }

        .image-hover-wrap-1,
        .image-hover-wrap-2 {
          width: 656px;
          height: 434px;
          overflow: hidden;
        }

        .image-hover-wrap-3 {
          width: 656px;
          height: 876px;
          overflow: hidden;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .press-release {
    position: relative;
    //padding-bottom: 80px;
    box-sizing: border-box;

    .section-1 {
      width: 100%;
      height: 100vh;
      background: url('../assets/images/press-release-background-mo.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
    }

    .section-2 {
      max-width: initial;
      width: 100%;
      margin: -120px auto 0px;
      z-index: 2;
      padding: 0px 10px;
      box-sizing: border-box;
      position: relative;

      .section-title {
        width: 100%;
        max-width: 1320px;
        height: 121px;
        background: url('../assets/images/press-release-setction-2-title-background.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        margin: 0px auto 40px;
        display: block;
        align-items: center;
        padding-left: 21px;
        box-sizing: border-box;
        z-index: 1;
        padding-top: 27px;

        p:first-child {
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 100%;
          color: #333333;
        }

        .sub-section {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .divider {
            width: 1px;
            height: 14px;
            background: #333333;
            margin: 0px 10px 0px 0px;
          }

          p:last-child {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            color: #333333;
          }
        }
      }

      .first-news-item {
        display: flex;
        margin-bottom: 80px;
        position: relative;

        img {
          width: 720px;
          height: 492px;
          object-fit: cover;
        }

        .content-wrap {
          position: absolute;
          width: 630px;
          height: 433px;
          right: 0px;
          top: 30px;
          background: #000000;
          padding: 54px 50px 0px 50px;
          box-sizing: border-box;

          .title {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 150%;
            color: #ffffff;
          }

          .date {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            color: #bcbfc0;
            margin: 20px 0px;
          }

          .content {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #ffffff;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }
      }

      .press-release-news {
        max-width: 1320px;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        a {
          text-decoration: none;
        }

        .news-item {
          width: 320px;
          min-height: initial;
          cursor: pointer;
          margin-right: 0px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 10px;
          box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
          min-height: 336px;

          .image-wrap {
            width: 100%;
            height: 210px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            img:hover {
              transform: none; /*  default */
              -webkit-transform: none; /*  크롬 */
              -moz-transform: none; /* FireFox */
              -o-transform: none; /* Opera */
              transition: transform 0.7s;
            }
          }

          .title {
            font-family: 'Noto Sans KR';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #333333;
            margin-top: 10px;
          }

          .date {
            font-family: 'Noto Sans KR';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 180%;
            color: #bcbfc0;
            margin-top: 10px;
          }
        }

        .news-item:hover {
          background: #ffffff;

          .title {
            color: #333333;
          }

          .date {
            color: #bcbfc0;
          }
        }

        .news-item:nth-child(3n) {
          margin-right: 0px;
          margin-bottom: 0px;
        }
      }

      .more-button {
        width: 163px;
        height: 48px;
        cursor: pointer;
        margin: 120px auto 160px;
      }
    }

    .section-3 {
      width: 100%;
      position: relative;
      margin: 0 auto;
      background: #333333;
      padding: 40px 10px 160px;
      box-sizing: border-box;

      .section-title {
        width: 100%;
        max-width: 1320px;
        height: 121px;
        background: url('../assets/images/press-release-setction-3-title-background.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        margin: 0px auto 40px;
        display: block;
        align-items: center;
        padding-left: 21px;
        box-sizing: border-box;
        z-index: 1;
        padding-top: 27px;

        p:first-child {
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 100%;
          color: #333333;
        }

        .sub-section {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .divider {
            width: 1px;
            height: 14px;
            background: #333333;
            margin: 0px 10px 0px 0px;
          }

          p:last-child {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            color: #333333;
          }
        }
      }

      .image-wrap {
        max-width: 640px;
        width: 100%;
        margin: 0 auto;
        padding: 0px;
        box-sizing: border-box;

        img {
          width: 100%;
        }

        img:hover {
          transform: scale(1); /*  default */
          -webkit-transform: scale(1); /*  크롬 */
          -moz-transform: scale(1); /* FireFox */
          -o-transform: scale(1); /* Opera */
          transition: transform 0.7s;
        }
      }
    }
  }
}
