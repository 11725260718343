//=============================
//=== Culture ===
//=============================
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600,700å&display=swap');

$titilliumWeb: 'Titillium Web', sans-serif;
$titilliumWebWeight: 700;
$notoSansKR: 'Noto Sans KR', sans-serif;
$transitionEvt: .5s ease;

.culture {
  //margin-top: -80px;

  .section-1 {
    width: 100%;
    height: 100vh;
    position: relative;

    .tab-wrap {
      position: absolute; left: 0; right: 0; bottom: 80px; z-index: 2;
      display: flex; justify-content: center; align-items: center;
      width: calc(100% - 136px); height: 131px;
      margin: 0 auto;
      border-bottom: 2px solid #f4f4f4; border-top-left-radius: 26px; border-top-right-radius: 26px;
      background: #fff;
      .tab {
        width: 100px; height: 130px;
        margin: 0 50px;
        font-family: $notoSansKR; font-style: normal; font-weight: 700; font-size: 20px; color: #333;
        line-height: 130px; text-align: center;
        cursor: pointer;
      }
      .tab.selected {
        color: #3fd6f5;
        border-bottom: 2px solid #3fd6f5;
      }
    }

    .slider-image-wrap {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        filter: brightness(50%);
        &.show-pc {
          display:block;
        }
        &.show-mobile {
          display:none;
        }
      }
    }
  }

  .slick-prev {
    left: 100px;
    z-index: 1;
    width: 56px;
    height: 56px;
  }

  .slick-next {
    right: 100px;
    z-index: 1;
    width: 56px;
    height: 56px;
  }

  .slick-dots {
    bottom: 230px;

    & > li button:before {
      color: white;
    }

    & > li.slick-active button:before {
      color: white;
      padding: 0px;
      border: 1px solid white;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }

  .section-2 {
    width: 100%;
    height: 1080px;
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      width: 430px;
      height: 601px;
      box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
      padding: 45px 0px 0px 40px;
      box-sizing: border-box;
      margin: 0px 7px;

      img {
        width: 182px;
        height: 225px;
        margin-bottom: 65px;
      }

      .title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 110%;
        margin-bottom: 14px;
      }

      .divider {
        position:relative;
        width: 100%;
        height: 1px;
        margin-bottom: 63px;
        
      }

      .content {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 150%;
        color: #333333;
      }
    }

    .card-1 {
      .title {
        color: #F1863E;
      }

      .divider {
        background: #F1863E;
        
      }
      &:hover {
        .title {
          position:relative;
          height:44px;
          overflow: hidden;
          span {
            position:absolute;
            top:0;
            left:0;
            animation:cultureListTitle .3s .1s linear both;
            -webkit-animation:cultureListTitle .3s .1s linear both;
          }
        }
      }
    }

    .card-2 {
      .title {
        color: #667B0B;
      }

      .divider {
        background: #667B0B;
      }
      &:hover {
        .title {
          position:relative;
          height:44px;
          overflow: hidden;
          span {
            position:absolute;
            top:0;
            left:0;
            animation:cultureListTitle .3s .1s linear both;
            -webkit-animation:cultureListTitle .3s .1s linear both;
          }
        }
      }
    }

    .card-3 {
      .title {
        color: #AC2ED8;
      }

      .divider {
        background: #AC2ED8;
      }
      &:hover {
        .title {
          position:relative;
          height:44px;
          overflow: hidden;
          span {
            position:absolute;
            top:0;
            left:0;
            animation:cultureListTitle .3s .1s linear both;
            -webkit-animation:cultureListTitle .3s .1s linear both;
          }
        }
      }
    }
  }
}

@keyframes cultureListBorder {
  0% {width: 0;}
  100% {width: 100%;}
}
@-webkit-keyframes cultureListBorder {
  0% {width: 0;}
  100% {width: 100%;}
}
@keyframes cultureListTitle {
  0% {top:50px;}
  100% {top:0;}
}
@-webkit-keyframes cultureListTitle {
  0% {top:50px;}
  100% {top:0;}
}
@media screen and (max-width: 1200px) {
  .culture {
    //margin-top: -80px;
    padding-bottom: 160px;
    box-sizing: border-box;

    .section-1 {
      width: 100%;
      height: 100vh;
      position: relative;

      .tab-wrap {
        width: 100%; height: 100px;
        .tab {margin:0; height:100px;}
      }
      
      .slider-image-wrap {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100vh;
          object-fit: cover;
          filter: brightness(50%);
          &.show-pc {
            display:none;
          }
          &.show-mobile {
            display:block;
          }
        }
      }
    }

    .slick-prev {
      left: 100px;
      z-index: 1;
      width: 56px;
      height: 56px;
    }

    .slick-next {
      right: 100px;
      z-index: 1;
      width: 56px;
      height: 56px;
    }

    .slick-dots {
      bottom: 230px;

      & > li button:before {
        color: white;
      }

      & > li.slick-active button:before {
        color: white;
        padding: 0px;
        border: 1px solid white;
        border-radius: 50%;
        box-sizing: border-box;
      }
    }

    .section-2 {
      width: 100%;
      height: initial;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      .card {
        width: 340px;
        height: 490px;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
        padding: 40px 0px 0px 40px;
        box-sizing: border-box;
        margin: 14px 0px;

        img {
          width: 146px;
          height: 193px;
          margin-bottom: 50px;
        }

        .title {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 110%;
          margin-bottom: 18px;
        }

        .divider {
          width: 100%;
          height: 1px;
          margin-bottom: 50px;
        }

        .content {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 150%;
          color: #333333;
        }
      }

      .card-1 {
        .title {
          color: #F1863E;
        }

        .divider {
          background: #F1863E;
        }
        &:hover {
          .title {
            height:31px;
          }
        }
      }

      .card-2 {
        .title {
          color: #667B0B;
        }

        .divider {
          background: #667B0B;
        }
        &:hover {
          .title {
            height:31px;
          }
        }
      }

      .card-3 {
        .title {
          color: #AC2ED8;
        }

        .divider {
          background: #AC2ED8;
        }
        &:hover {
          .title {
            height:31px;
          }
        }
      }
    }
  }
}