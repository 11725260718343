//=============================
//=== Story ===
//=============================
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600,700å&display=swap');

$titilliumWeb: 'Titillium Web', sans-serif;
$titilliumWebWeight: 700;
$notoSansKR: 'Noto Sans KR', sans-serif;
$transitionEvt: .5s ease;

.story {

  .section-1 {
    height: 100vh;
    //min-height: 1080px;
    position: relative;

    .tab-wrap {
      position: absolute; left: 0; right: 0; bottom: 80px; z-index: 2;
      display: flex; justify-content: center; align-items: center;
      width: calc(100% - 136px); height: 131px;
      margin: 0 auto;
      border-bottom: 2px solid #f4f4f4; border-top-left-radius: 26px; border-top-right-radius: 26px;
      background: #fff;
      .tab {
        width: 100px; height: 130px;
        margin: 0 50px;
        font-family: $notoSansKR; font-style: normal; font-weight: 700; font-size: 20px; color: #333;
        line-height: 130px; text-align: center;
        cursor: pointer;
      }
      .tab.selected {
        color: #3fd6f5;
        border-bottom: 2px solid #3fd6f5;
      }
    }
    .section-1-background {
      width: 100%;
      height: 100vh;
      background: url("../assets/story/story-section-1-background.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
      background-attachment: fixed;
    }

    .section-1-image {
      width: 1033px;
      height: 453px;
      background: url("../assets/story/story-section-1-1.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 0px;
      right: 0;
      bottom: -120px;
      margin: 0 auto;
      z-index: 2;
    }
  }

  .section-1-5 {
    width: 100%;
    height: 849px;
    margin-top: 120px;
    position: relative;
    text-align: center;
    padding-top: 80px;
    box-sizing: border-box;

    video {
      width: 1320px;
      height: 689px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }

    .background {
      width: 100%;
      height: 100%;
      background: #E4F4FF;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
    }
  }

  .section-2 {
    height: 1080px;
    padding-top: 191px;
    box-sizing: border-box;
    position: relative;
    display: flex;

    .green-line {
      width: calc((100% - 1061px)/2);
      height: 2px;
      background: #13C2C2;
      margin-top: 200px;
    }

    .purple-line {
      width: calc((100% - 1061px)/2);
      height: 2px;
      background: #667AF3;
      margin-top: 500px;
    }

    .content-wrap {
      width: 1061px;
      height: 701px;
      margin: 0 auto;
      position: relative;
      display: flex;

      .content {
        width: 340px;
        height: 450px;
        box-sizing: border-box;
        padding: 33px 0px 0px;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
        text-align: center;
        position: relative;
        background: #FFFFFF;
        z-index: 1;

        .title {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 180%;
          text-align: center;
          color: #FFFFFF;
          height: 36px;
          border-radius: 40px;
          margin: 0 auto;
          box-sizing: border-box;
          width: fit-content;
          padding: 0px 20px;
        }

        .description {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 150%;
          text-align: center;
          color: #555555;
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }

      .blue-line-1 {
        width: 35px;
        height: 2px;
        background: #6AB8F3;
        margin-top: 220px;
        transform: rotate(45deg);
        position: absolute;
        top: 0px;
        left: 333px;
        z-index: 0;
      }

      .blue-line-2 {
        width: 35px;
        height: 2px;
        background: #667AF3;
        margin-top: 450px;
        transform: rotate(45deg);
        position: absolute;
        top: 0px;
        right: 333px;
        z-index: 0;
      }

      .content-1 {
        margin-right: 20px;

        .title {
          background: #13C2C2;
        }

        .description {
          span {
            color: #13C2C2;
          }
        }
      }

      .content-2 {
        margin-right: 20px;
        margin-top: 140px;

        .title {
          background: #6AB8F3;
        }

        .description {
          span {
            color: #6AB8F3;
          }
        }
      }

      .content-3 {
        margin-top: 250px;

        .title {
          background: #667AF3;
        }

        .description {
          span {
            color: #667AF3;
          }
        }
      }
    }
  }

  .section-3 {
    width: 100%;
    height: 1003px;
    background: url("../assets/story/story-section-3-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-top: 177px;
    box-sizing: border-box;
    background-attachment: fixed;

    .content-wrap {
      text-align: center;

      .text-1 {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 150%;
        color: #FFFFFF;
        margin-bottom: 77px;
      }

      .text-2 {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 200%;
        color: #FFFFFF;
      }

      .divider {
        width: 1px;
        height: 80px;
        background: #76E8FF;
        margin: 80px auto;
      }

      .text-3 {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 180%;
        text-align: center;

        color: #76E8FF;
      }
    }
  }

  .section-4 {

    .content-wrap {
      height: 640px;

      .content-sub-wrap {
        width: 985px;
        height: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: start;
        position: relative;

        & > .title {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 150%;
          display: flex;
          align-items: center;
          color: #000000;
          border-left: 1px solid black;
          padding-left: 25px;
        }

        .content {
          width: 624px;
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 150%;
          color: #000000;

          span {
            font-family: "Titillium Web";
            font-weight: 600;
            font-size: 32px;
          }
        }

        .background-text-1 {
          width: 395px;
          height: 181px;
          position: absolute;
          opacity: 0.1;
          right: 100px;
        }

        .background-text-2 {
          width: 437px;
          height: 138px;
          position: absolute;
          right: -100px;
          bottom: 160px;
        }

        .background-text-3 {
          width: 386px;
          height: 144px;
          position: absolute;
          left: 0px;
          top: 100px;
          z-index: 1;
        }

        .content-1 {
          span {
            color: #36CFC9;
          }
        }

        .content-2 {
          span {
            color: #6AB8F3;
          }
        }

        .content-3 {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .item-wrap {
            width: 300px;
            height: 360px;
            box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
            text-align: center;
            box-sizing: border-box;
            padding-top: 17px;
            position: relative;
            z-index: 2;
            background: #FFFFFF;

            .title {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 32px;
              text-align: center;
              color: #333333;
              display: block;
            }

            .divider {
              width: 24px;
              height: 1px;
              background: #BCBFC0;
              margin: 12px auto;
            }

            .description {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 32px;
              text-align: center;
              color: #555555;
            }
          }

          .item-wrap:nth-child(1),
          .item-wrap:nth-child(3) {
            margin-right: 24px;
          }

          .item-wrap:nth-child(1),
          .item-wrap:nth-child(2) {
            margin-bottom: 24px;
          }

          .item-wrap:nth-child(1) {
            img {
              width: 134px;
              height: 195px;
              margin-bottom: 10px;
            }
          }

          .item-wrap:nth-child(2) {
            img {
              width: 104px;
              height: 187px;
              margin-bottom: 10px;
            }
          }

          .item-wrap:nth-child(3) {
            img {
              width: 180px;
              height: 184px;
              margin-bottom: 10px;
            }
          }

          .item-wrap:nth-child(4) {
            img {
              width: 158px;
              height: 179px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .content-wrap-1 {
      padding-top: 266px;
      box-sizing: border-box;
    }

    .content-wrap-2 {
      padding-top: 192px;
      width: 100%;
      background: #E4F4FF;
      box-sizing: border-box;
    }

    .content-wrap-3 {
      height: auto;
      margin-top: 224px;
      margin-bottom: 180px;
      position: relative;

      .purple-line {
        width: 60vw;
        height: 246px;
        background: #667AF3;
        border-radius: 5px 0px 0px 5px;
        position: absolute;
        right: 0px;
        top: -25px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .story {

    .section-1 {
      height: 100vh;
      //min-height: 1080px;
      position: relative;

      .tab-wrap {
        width: 100%; height: 100px;
        .tab {margin:0; height:100px;}
      }
      .section-1-background {
        width: 100%;
        height: 100vh;
        background: url("../assets/story/story-section-1-background-mo.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
      }

      .section-1-image {
        width: 360px;
        height: 162px;
        background: url("../assets/story/mobile-story-section-1-1.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 0px;
        right: 0;
        bottom: 95px;
        margin: 0 auto;
        z-index: 2;
      }
    }

    .section-1-5 {
      width: 100%;
      height: auto;
      margin-top: 0px;
      margin-bottom: 60px;
      position: relative;
      padding-top: 20px;

      video {
        width: calc(100vw - 40px);
        height: auto;
        min-width: 320px;
        max-width: 640px;
        min-height: 320px;
        max-height: 640px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
      }

      .background {
        width: 100%;
        height: auto;
        background: #E4F4FF;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: 1;
        margin-bottom: -20px;
      }
    }

    .section-2 {
      height: initial;
      padding-top: initial;
      box-sizing: border-box;
      position: relative;
      display: flex;
      margin-top: 160px;

      .green-line {
        display: none;
      }

      .purple-line {
        display: none;
      }

      .content-wrap {
        width: initial;
        height: initial;
        margin: 0 auto;
        position: relative;
        display: initial;

        .content {
          width: 320px;
          height: 343px;
          box-sizing: border-box;
          padding: 33px 0px 0px;
          box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
          text-align: center;
          position: relative;
          background: #FFFFFF;
          z-index: 1;
          margin-bottom: 24px;
          border-radius: 15px;

          .title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 180%;
            text-align: center;
            color: #FFFFFF;
            height: 36px;
            border-radius: 40px;
            margin: 0 auto;
            box-sizing: border-box;
            width: fit-content;
            padding: 0px 20px;
          }

          .description {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            text-align: center;
            color: #555555;
            margin-top: 15px;
            margin-bottom: 15px;
          }

          img {
            height: 162px;
          }
        }

        .blue-line-1 {
          display: none;
        }

        .blue-line-2 {
          display: none;
        }

        .content-1 {
          margin-right: 00px;

          .title {
            background: #13C2C2;
          }

          .description {
            span {
              color: #13C2C2;
            }
          }
        }

        .content-2 {
          margin-right: 0px;
          margin-top: 0px;

          .title {
            background: #6AB8F3;
          }

          .description {
            span {
              color: #6AB8F3;
            }
          }
        }

        .content-3 {
          margin-top: 0px;
          margin-bottom: 120px;

          .title {
            background: #667AF3;
          }

          .description {
            span {
              color: #667AF3;
            }
          }
        }
      }
    }

    .section-3 {
      width: 100%;
      height: initial;
      background: url("../assets/story/story-section-3-1.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      padding: 120px 0px;
      box-sizing: border-box;

      .content-wrap {
        text-align: center;

        .text-1 {
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 150%;
          color: #FFFFFF;
          margin-bottom: 77px;
        }

        .text-2 {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 200%;
          color: #FFFFFF;
        }

        .divider {
          width: 1px;
          height: 80px;
          background: #76E8FF;
          margin: 80px auto;
        }

        .text-3 {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 180%;
          text-align: center;

          color: #76E8FF;
        }
      }
    }

    .section-4 {
      //padding-top: 120px;

      .content-wrap {

        .content-sub-wrap {
          width: initial;
          margin: 0 auto;
          box-sizing: border-box;
          display: block;
          justify-content: initial;
          align-items: initial;
          margin-bottom: 120px;
          position: relative;
          padding-left: 20px;

          .title {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 150%;
            display: block;
            align-items: initial;
            color: #000000;
            margin-bottom: 32px;
          }

          .content {
            width: initial;
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            color: #000000;

            span {
              font-family: "Titillium Web";
              font-weight: 600;
              font-size: 24px;
            }
          }

          .background-text-1 {
            width: 187px;
            height: 85px;
            position: absolute;
            opacity: 0.1;
            right: 100px;
            bottom: 200px;
          }

          .background-text-2 {
            width: 271px;
            height: 86px;
            position: absolute;
            right: 20px;
            bottom: 150px;
          }

          .background-text-3 {
            width: 229px;
            height: 86px;
            position: absolute;
            left: 50px;
            top: -30px;
            z-index: 1;
          }

          .content-1 {
            span {
              color: #36CFC9;
            }
          }

          .content-2 {
            span {
              color: #6AB8F3;
            }
          }

          .content-3 {
            display: flex;
            flex-direction: column;
            flex-wrap: initial;
            align-items: center;

            .item-wrap {
              width: 320px;
              height: 360px;
              box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
              text-align: center;
              box-sizing: border-box;
              padding-top: 17px;
              position: relative;
              z-index: 2;
              background: #FFFFFF;

              .title {
                font-family: 'Noto Sans KR', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                text-align: center;
                color: #333333;
                display: block;
                margin-bottom: 0px;
              }

              .divider {
                width: 24px;
                height: 1px;
                background: #BCBFC0;
                margin: 10px auto;
              }

              .description {
                font-family: 'Noto Sans KR', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                text-align: center;
                color: #555555;
              }
            }

            .item-wrap:nth-child(1),
            .item-wrap:nth-child(3) {
              margin-right: 0px;
              margin-bottom: 14px;
            }

            .item-wrap:nth-child(1),
            .item-wrap:nth-child(2) {
              margin-bottom: 14px;
            }

            .item-wrap:nth-child(1) {
              img {
                width: 134px;
                height: 195px;
                margin-bottom: 10px;
              }
            }

            .item-wrap:nth-child(2) {
              img {
                width: 104px;
                height: 187px;
                margin-bottom: 10px;
              }
            }

            .item-wrap:nth-child(3) {
              img {
                width: 180px;
                height: 184px;
                margin-bottom: 10px;
              }
            }

            .item-wrap:nth-child(4) {
              img {
                width: 158px;
                height: 179px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      .content-wrap-2 {
        width: 100%;
        height: 640px;
        background: #E4F4FF;
        padding-top: 192px;
        box-sizing: border-box;
      }

      .content-wrap-3 {
        margin-top: 135px;
        position: relative;

        .content-sub-wrap {
          box-sizing: border-box;
          padding-right: 20px;
        }

        .purple-line {
          width: 60vw;
          height: 246px;
          background: #667AF3;
          border-radius: 5px 0px 0px 5px;
          position: absolute;
          right: 0px;
          top: 50px;
        }
      }
    }
  }



}