//=============================
//=== Healthy ===
//=============================

.healthy {
  .section-1 {
    background-image: url("../assets/brands/healthy-1.jpg");
    background-attachment: fixed;

    .section-1-text-wrap {
      top: 21vh;
      right: 29vw;
      color: #555555
    }
  }
}

.inhaver {
  .section-1 {
    background-image: url("../assets/brands/inhaver-1.jpg");
    background-attachment: fixed;


    .section-1-text-wrap {
      top: 33vh;
      left: 19vw;
      color: #FFFFFF
    }
  }
}

.ivim {
  .section-1 {
    background-image: url("../assets/brands/ibim-1.jpg");
    background-attachment: fixed;


    .section-1-text-wrap {
      top: 33vh;
      left: 19vw;
      color: #555555
    }
  }
}

.gshi {
  .section-1 {
    background-image: url("../assets/brands/Gshine-1.jpg");
    background-attachment: fixed;


    .section-1-text-wrap {
      top: 33vh;
      right: 14vw;
      color: #333333
    }
  }
}

.ruthair {
  .section-1 {
    background-image: url("../assets/brands/roothair-1.jpg");
    background-attachment: fixed;


    .section-1-text-wrap {
      top: 26vh;
      left: 15vw;
      color: #FFFFFF;
    }
  }
}

.healthy,
.inhaver,
.ivim,
.ruthair,
.gshi {
  //width: 1920px;
  //margin: -80px auto 0;
  padding-bottom: 105px;
  box-sizing: border-box;

  .section-1 {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .section-1-text-wrap {
      font-family: 'Noto Sans KR', sans-serif;
      position: absolute;

      .section-1-title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 180%;
        margin-bottom: 60px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
      }
    }
  }

  .section-2 {
    width: 1320px;
    height: 890px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;

    .brand-logo {
      width: 1320px;
      height: 150px;
      margin-top: 85px;
    }

    .section-3 {
      display: flex;
      margin-top: 60px;
      justify-content: space-between;

      .section-4 {
        display: flex;

        .product-image-1 {
          width: 570px;
          height: 680px;
          margin-right: 20px;
        }

        .section-6 {

          .product-image-2 {
            width: 330px;
            height: 330px;
          }

          .product-image-3 {
            width: 330px;
            height: 330px;
            margin-top: 20px;
          }
        }
      }

      .section-5 {
        .title {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 140%;
          text-align: right;
          color: #333333;
          margin-top: 120px;
        }

        .description {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          text-align: right;
          color: #555555;
          margin-top: 75px;
        }

        .shop-button {
          width: 179px;
          height: 48px;
          margin-top: 187px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.ivim {

  .section-4-1 {
    display: block;

    div {
      display: flex;

      div {
        width: 277px;
        height: 330px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      div:nth-child(1),
      div:nth-child(2) {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .healthy {
    .section-1 {
      background-image: url("../assets/brands/mobile-healthy-1.jpg");

      .section-1-text-wrap {
        color: #555555
      }
    }
  }

  .inhaver {
    .section-1 {
      background-image: url("../assets/brands/mobile-inhaver-1.jpg");
    }
  }

  .ivim {
    .section-1 {
      background-image: url("../assets/brands/mobile-ibim-1.jpg");
    }
  }

  .gshi {
    .section-1 {
      background-image: url("../assets/brands/mobile-Gshine-1.jpg");

      .section-1-text-wrap {
        color: #FFFFFF
      }
    }
  }


  .ruthair {
    .section-1 {
      background-image: url("../assets/brands/mobile-roothair-1.jpg");
    }
  }

  .healthy,
  .inhaver,
  .ivim,
  .ruthair,
  .gshi {
    //padding-top: 116px;
    padding-bottom: 105px;
    box-sizing: border-box;

    .section-1 {
      width: 100%;
      height: 800px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      .section-1-text-wrap {
        font-family: 'Noto Sans KR', sans-serif;
        position: absolute;
        top: 116px;
        left: 24px;
        right: initial;

        .section-1-title {
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 180%;
          margin-bottom: 32px;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
        }
      }
    }

    .section-2 {
      width: 100%;
      height: auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;

      .brand-logo {
        width: 100%;
        height: 150px;
        margin-top: 48px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .section-3 {
        display: block;
        margin-top: 0px;
        justify-content: space-between;

        .section-4 {
          display: block;

          .product-image-1 {
            width: 320px;
            height: 381px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 5px;
            margin: 12px auto;
          }

          .section-6 {

            .product-image-2 {
              width: 320px;
              height: 320px;
              margin: 12px auto;
            }

            .product-image-3 {
              width: 320px;
              height: 320px;
              margin: 12px auto;
            }
          }
        }

        .section-5 {
          .title {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 140%;
            text-align: center;
            color: #333333;
            margin-top: 90px;
          }

          .description {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            text-align: center;
            color: #555555;
            margin-top: 32px;
          }

          .shop-button {
            width: 163px;
            height: 48px;
            margin: 60px auto 0px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .back-button {
      margin-top: 12px;
    }
  }

  .ivim {

    .section-4-1 {
      display: block;

      div {
        display: block;

        div {
          width: 320px;
          height: 381px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          margin: 12px auto;
        }

        div:nth-child(1),
        div:nth-child(2) {
          margin: 12px auto;
        }
      }
    }
  }
}