//=============================
//=== News ===
//=============================

.news-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}

.news-page {
  width: 1060px;
  padding-top: 80px;
  padding-bottom: 160px;
  box-sizing: border-box;
  margin: 0 auto;

  .title {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 32px;
    color: #333333;
    margin-top: 160px;
    margin-bottom: 8px;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #bcbfc0;
    padding-bottom: 30px;
    margin-bottom: 32px;

    .date {
      font-size: 14px;
      font-family: 'Noto Sans KR', sans-serif;
      color: #bcbfc0;
    }

    .share-button {
      cursor: pointer;
    }
  }

  & > div:nth-child(3) {
    .image-wrap {
      text-align: center;
      margin-bottom: 120px;
      img {
        max-width: 100%;
      }
    }

    pre {
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 18px;
      line-height: 180%;
      word-break: break-word;
      white-space: pre-wrap;
      overflow: auto;
    }
  }

  .alert-box {
    position: fixed;
    width: 388px;
    height: 218px;
    background: #f6f8f9;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    margin-top: -109px; /* Negative half of height. */
    margin-left: -194px; /* Negative half of width. */
    box-sizing: border-box;
    padding-top: 59px;
    z-index: 9;

    p {
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 20px;
      text-align: center;
    }

    div {
      width: 130px;
      height: 42px;
      border: 1px solid #333333;
      border-radius: 24px;
      text-align: center;
      cursor: pointer;
      margin: 32px auto 0px;
      line-height: 42px;
      box-sizing: border-box;
    }
  }

  .back-button {
    width: 163px;
    height: 48px;
    cursor: pointer;
    margin: 120px auto 0px;
  }
}

@media screen and (max-width: 1200px) {
  .news-page {
    width: 100%;
    padding: 116px 20px 160px;
    box-sizing: border-box;
    margin: 0 auto;

    .title {
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 20px;
      color: #333333;
      margin-top: 0px;
      margin-bottom: 8px;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #bcbfc0;
      padding-bottom: 8px;
      margin-bottom: 32px;

      .date {
        font-size: 14px;
        font-family: 'Noto Sans KR', sans-serif;
        color: #bcbfc0;
      }

      .share-button {
        cursor: pointer;
      }
    }

    & > div:nth-child(3) {
      img {
        width: 100%;
        // margin-bottom: 120px;
      }

      p {
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 18px;
        line-height: 180%;
        word-break: break-word;
      }
    }

    .alert-box {
      position: fixed;
      width: 388px;
      height: 218px;
      background: #f6f8f9;
      border-radius: 10px;
      top: 50%;
      left: 50%;
      margin-top: -109px; /* Negative half of height. */
      margin-left: -194px; /* Negative half of width. */
      box-sizing: border-box;
      padding-top: 59px;
      z-index: 9;

      p {
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 20px;
        text-align: center;
      }

      div {
        width: 130px;
        height: 42px;
        border: 1px solid #333333;
        border-radius: 24px;
        text-align: center;
        cursor: pointer;
        margin: 32px auto 0px;
        padding: 12px 0px;
        box-sizing: border-box;
      }
    }

    .back-button {
      width: 163px;
      height: 48px;
      cursor: pointer;
      margin: 120px auto 0px;
    }
  }
}
