@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600,700å&display=swap');

$titilliumWeb: 'Titillium Web', sans-serif;
$titilliumWebWeight: 700;
$notoSansKR: 'Noto Sans KR', sans-serif;
$transitionEvt: .5s ease;

@mixin titilliumWebStyle(){
  font-family: $titilliumWeb;
  font-weight: $titilliumWebWeight;
}

@mixin noneSelectText(){
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}

.company-history {
  position: relative;
  .fixed-year-selector {
    position: fixed; top: 250px; right: 30px; z-index: 5;
    width: 100px;
    padding: 10px 0;
    background-color: #e2f9fe; border-radius: 22px;
    &.hidden {display: none;}
    ul {
      position: relative; z-index: 2;
      li {
        width: 100%; height: 44px;
        margin-bottom: 10px;
        font-family: 'Titillium Web', sans-serif; font-weight: 600; font-size: 20px; text-align: center;
        line-height: 44px; cursor: pointer; border-radius: 22px;
        transition: $transitionEvt;
        @include noneSelectText();
        &:last-child {margin-bottom: 0;}
        &.active {
          width: 100%; height: 44px;
          color:#fff;
          cursor: auto; background: #000;
        }
      }
    }

  }
  .section-1 {
    position: relative; height: 100vh;
    padding-top: 80px;
    box-sizing: border-box;
    .background-video {
      position: absolute; top: 0; right: 0; bottom: 0; left: 0;
      width: 100%; height: 100vh;
      overflow: hidden;
      video {
        object-fit: cover;
        width: 100vw; height: 100vh;
      }
    }
    .section-1-background {
      width: 100%;
      height: 100vh;
      background: url("../assets/companyHistory/history-section-1-background.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
      background-attachment: fixed;
    }
    .overlay {
      position: absolute; z-index: 1; top: 0; right: 0; bottom: 0; left: 0; height: 100vh;
      background-color: #000; opacity: 0.3;
    }
    .tab-wrap {
      position: absolute; left: 0; right: 0; bottom: 80px; z-index: 2;
      display: flex; justify-content: center; align-items: center;
      width: calc(100% - 136px); height: 131px;
      margin: 0 auto;
      border-bottom: 2px solid #f4f4f4; border-top-left-radius: 26px; border-top-right-radius: 26px;
      background: #fff;
      .tab {
        width: 100px; height: 130px;
        margin: 0 50px;
        font-family: $notoSansKR; font-style: normal; font-weight: 700; font-size: 20px; color: #333;
        line-height: 130px; text-align: center;
        cursor: pointer;
      }
      .tab.selected {
        color: #3fd6f5;
        border-bottom: 2px solid #3fd6f5;
      }
    }
  }

  .main-section {
    width: 1321px; z-index: 2;
    margin: -130px auto 0px; padding-bottom: 180px;
    box-sizing: border-box;
    .since-block {
      margin-top: 189px;
      .title {
        @include titilliumWebStyle();
        text-align: center; color: #000; line-height: 100px;
        * {transition: $transitionEvt}
        p {font-size: 32px;}
        span {font-size: 84px;}
      }
      .banner {
        position: relative;
        display: flex; justify-content: center; align-items: center;
        height: auto;
        margin-top: 120px;
        img {width: 100%;}
        p {
          position: absolute; z-index: 1;
          font-family: $notoSansKR; font-size: 40px; font-weight: 700; color: #fff;
          text-align: center;
          transition: font-size $transitionEvt;
        }
      }
      .history-list {
        display: flex; justify-content: space-between;
        margin-top: 86px;
        > .year {
          width: 500px;
          &.fixed {position: sticky; top: 110px; left: 0; z-index: 1; height: 50vh; }
          p {
            @include titilliumWebStyle(); font-size: 72px;
          }
          div {
            max-width: 500px;
            margin-top: 30px;
            border-radius: 35px 35px;
            overflow: hidden;
            img {
              display: block; width: 100%; height: 100%; transition: opacity 0.5s ease-in-out; opacity: 1;
              &.hidden { opacity: 0; }
            }
          }
        }
        .list {
          width: 762px;
          > ul {}
          > ul > li {
            display: flex; justify-content: space-between;
            margin-bottom: 60px; padding-bottom: 60px;
            border-bottom: 1px solid #000;
          }
          > ul > li > .year {
            @include titilliumWebStyle(); font-size: 32px;
            line-height: 72px;
          }
          > ul > li > div {width: 660px}
          > ul > li > div > .itemGroup {
            > .type {
              position: relative;
              display: flex; justify-content: center;
              margin: 32px 0;
              i {
                position: absolute; top: 12px; left: 0;
                display: block; width: 100%; height:1px;
                background:#3FD6F5;
              }
              p {
                position: relative; z-index: 1;
                padding: 0 40px;
                @include titilliumWebStyle(); font-size: 16px; color: #00bbe2;
                line-height: 24px; background:#fff;
              }
            }
            > div {
              display: flex; justify-content: space-between;
            }
            > div > p {
              font-family: $notoSansKR; font-size: 20px; font-weight: 700; color: #000;
              line-height: 72px; word-break: keep-all;
            }
            > div > ul { width: 510px; }
            > div > ul li {
              padding:21px 0;
              font-family: $notoSansKR; font-size: 20px; font-weight: 400; color: #000;
              letter-spacing: -0.5px;
              &.point {
                font-weight: 500; color: #0053ff;
                img {
                  display: none; width: 100%;
                  margin-top: 5px;
                  border-radius: 20px 20px 20px 20px;
                }
              }
              &.effect {
                position: relative;
                margin-bottom: 15px;
                i {
                  position: absolute; top: -15px; left: 0;
                  display: block; width: 0; height: 0;
                  border-right: 20px solid transparent; border-bottom: 15px solid #f2f2f2;
                }
                padding: 21px 40px;
                font-weight: 500;
                background: #f2f2f2; border-radius: 0 20px 20px 20px;
              }
            }
            .imgIcon {
              position: relative; top: 4px;
              display:inline-block; width: 24px; height: 24px;
              margin-left: 20px;
              background-image: url(./../assets/companyHistory/imgIcon.svg);
              background-size: 24px 24px;
              transition: $transitionEvt;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1480px) {
  .company-history {
    .fixed-year-selector {display: none;}
    .section-1 {
      .tab-wrap {bottom: 95px;}
    }
    .main-section {
      width: 100%;
      .since-block .banner {}
      .since-block .history-list {
        .list {
          > ul > li > div > .itemGroup {
            > div > ul {width: 100%;}
            .imgIcon {
              top: 2px;
              width: 20px; height: 20px;
              margin-left: 10px;
              background-size: 20px 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .company-history {
    .fixed-year-selector {display: none;}
    .section-1 {
      .tab-wrap {
        width: 100%; height: 100px;
        .tab {margin:0; height:100px;}
      }
    }
    .section-1-background {
      width: 100%;
      height: 100vh;
      background: url("../assets/companyHistory/history-section-1-background.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
    }
    .main-section {
      .since-block {margin-top: 125px;}
      .since-block .title {
        p {line-height:50px;}
        span {font-size: 50px}
      }
      .since-block .banner {
        width: 90%;
        margin: 10px auto 0;
        p {font-size: 3vw;}
      }
      .since-block .history-list {
        margin-top: 20px;
        > .year {display: none}
        .list {
          width: 90%;
          margin: 0 auto;
          > ul {}
          > ul > li {
            flex-direction: column;
          }
          > ul > li .year {font-size: 40px;}
          > ul > li > div {width:100%}
          > ul > li > div > .itemGroup {
            > div {
              flex-direction: column;
              > p {
                margin-top: 20px; margin-bottom: 10px;
                line-height: 30px;
              }
              &.type p {margin: 0 auto; padding:0 15px;}
              > ul {}
              > ul li {
                padding: 10px 0;
                &.point {
                  img {display: block;}
                }
                &.effect {
                  margin-top: 3px; padding: 10px 20px;
                  border-radius: 0 10px 10px 10px;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}