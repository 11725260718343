@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600,700å&display=swap');

//=============================
//=== Investment ===
//=============================
$titilliumWeb: 'Titillium Web', sans-serif;
$notoSansKR: 'Noto Sans KR', sans-serif;
$oneMobileRegular: 'ONE Mobile Regular', sans-serif;

@mixin transformCommon($value){
  transform: $value; /*  default */
  -webkit-transform: $value; /*  크롬 */
  -moz-transform: $value; /* FireFox */
  -o-transform: $value; /* Opera */
}

.investment {
  position: relative;
  .section-1 {
    position: relative;
    width: 100%; height: 100vh;
    background-image: url("../assets/images/investment-section-1-background.jpg");
    background-size: cover; background-repeat: no-repeat; background-position: center; background-attachment: fixed;
  }
  .section-body {
    position:relative;
    .section-content-box .section-title {
      position: relative; z-index: 1;
      display: flex;
      align-items: center; justify-content: center;
      width: auto; height: 228px;
      margin: -120px 148px 80px;
      background-size: cover;
      box-sizing: border-box;
      border-radius: 10px;
      overflow: hidden;
      p {
        * {
          display: inline-block; width: 100%;
          text-align: center; line-height: 180%; color: #FFF; word-break: keep-all;
        }
        span:first-child {
          font-family: $titilliumWeb; font-size: 48px; font-weight: 700; line-height: 69px;
        }
        span:last-child { font-family: $notoSansKR; font-size: 24px; font-weight: 700; }
      }
    }
    .section-content-box {
      .effect-comment-box {
        display: flex; align-items: center;
        margin: 137px 397px;
        font-family: $notoSansKR; font-weight: 700; font-size: 32px; color: #333333;
        letter-spacing: 0;
        &.show-pc { display: flex }
        &.show-mobile { display: none }
        .title-bar {
          display: inline-block;
          width: 8px; height: 54px;
          margin-right: 32px;
          background: #6C7EFC;
          border-radius: 3px;
        }
        .cr_lavender {
          margin-left: 12px;
          font-size: 45px; font-weight: 700;
          color: #6C7EFC;
          letter-spacing: 0;
        }
      }
    }
    // 플랫폼 영역 section-name-platform
    .section-content-box.section-name-platform {
      .section-title {
        background: url("../assets/platform/investment_S01.png") no-repeat center;
      }
      .sliding-comment-title-box {
        margin: 0 398px;
        .moving-txt-box {
          font-family: $notoSansKR; font-size: 42px; font-weight: 600;
          line-height: 63px;
          .cr_lavender { padding-left: 14px; color:#6C7DFC; }
          .txt_bg {
            position: relative; z-index: 1;
            border-radius: 10px;
            color: #FFF;
            &:before {
              content: '';
              position:absolute; z-index: -1; top: 0; left: 0;
              width: 0; height: 100%;
              background-color: #6C7DFC;
              border-radius: 10px;
            }
          }
          &.aos-animate {
            .txt_bg {
              &:before {
                animation:bgText 1s .3s linear both;
                -webkit-animation:bgText 1s .3s linear both;
              }
              @keyframes bgText {
                0% {width: 0;}
                100% {width: 100%;}
              }
              @-webkit-keyframes bgText {
                0% {width: 0;}
                100% {width: 100%;}
              }
            }
          }
        }
      }
      .comment-title-box {
        //height: 1357px;
        //padding: 137px 0;
        padding: 0;
        box-sizing: border-box; text-align: center;
        img {
          width: 100%; height: auto;
          max-width: 1320px;
          margin: 0 auto;
          object-fit: cover;
        }
      }
      .title-and-banner-box {
        padding: 137px 0;
        box-sizing: border-box; text-align: center;
        .creator-profitable-experience-space {
          position: relative; z-index: 1;
          align-items: center; justify-content: center;
          display: flex;
          height: 480px;
          margin: 0 148px;
          background-image: url("../assets/platform/investment_sub02.png");
          background-size: cover; background-repeat: no-repeat; background-position: center; box-sizing: border-box;
          border-radius: 12px;
          p {
            font-family: $notoSansKR;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 180%;
            color: white;
            word-break: keep-all;
            text-align: center;
            strong {
              font-size: 48px;
            }
          }
          > div {
            p {
              font-size: 32px;
              font-weight: 700;
              color: #FFF;
            }
            .cr_lavender {
              font-size: 48px;
              font-weight: 700;
              color: #3FD6F5;
            }
          }
        }
      }
    }

    // 블록체인 영역 section-name-blockchain
    .section-content-box.section-name-blockchain {
      margin-top: 100px;
      .section-title { background: url("../assets/platform/investment_S02.png") no-repeat center }
      .industrial-technology-4th {
        position: relative; z-index: 2;
        margin: 0 auto;
        width: 1060px;
        .title {
          width: 1063px; height: 50px;
          margin-bottom: 16px;
          font-family: $notoSansKR; font-style: normal; font-weight: 600; font-size: 28px;
          color: #FFFFFF; text-align: center; line-height: 180%;
          border-radius: 18px;
        }
        .blue-title { background: #3FD6F5; }
        .content-list {
          display: flex; justify-content: space-between;
          width: 100%;
        }
        .content-list .content {
          display: flex; align-items: center;
          width: 522px; height: 200px;
          padding-left: 34px;
          background: #FFF;
          box-shadow : 1px 2px 8px #0000001F;
          border-radius: 10px; box-sizing: border-box;
          img { margin-right: 25px; width: 33%}
          p {
            font-family: $notoSansKR; font-weight: 400; font-size: 20px; color: #333;
            line-height: 180%;
          }
        }
      }
      .card-list {
        display: flex; justify-content: center; align-items: center;
        width: 100%; height: 1080px;
        .card {
          width: 500px; height: 930px;
          margin: 0 1%; padding: 40px;
          background: #FFF;
          border: 1px solid rgba(0,0,0,0.05); border-radius: 18px;
          box-shadow: 1px 2px 8px 0 rgba(0,0,0,0.08);
          box-sizing: border-box;
          &.card-1 .title span { color: #6870FA; }
          &.card-2 .title span { color: #AD86FF; }
          &.card-3 .title span { color: #5F96FA; }
          .text-wrap {
            .number {
              position: relative;
              font-family: $oneMobileRegular; font-style: normal; font-weight: 400; font-size: 80px; color: #555;
              line-height: 180%;
              p { position: relative; z-index: 1; }
              .background {
                position: absolute; z-index: 0; top: 60px; left: 10px;
                width: 50px; height: 50px;
                border-radius: 50%;
                &.back-color1 { background: #B3B7FE; }
                &.back-color2 { background: #AD86FF; }
                &.back-color3 { background: #5F96FA; }
              }
            }
            & > img { width: 100%; height: 210px; margin-bottom: 65px; }
            .title, .text { font-family: $oneMobileRegular; color: #333; line-height: 180%; }
            .title { margin-bottom: 40px; font-weight: 700; font-size: 32px; }
            .text { font-weight: 400; font-size: 20px; }
          }
        }
      }
    }
    .section-content-box.section-name-contents {
      margin: 145px auto;
      .section-title { background: url("../assets/platform/investment_S03.png") no-repeat center; }
      & > div { width: 100%; max-width: 1320px; margin: 0 auto }
      .masonry-grid {
        position: relative; z-index: 2;
        display: flex; display: -webkit-box; display: -ms-flexbox;
        width: 100%;
        margin-top: 30px;
        .image-wrap {
          overflow: hidden; background-color: #FFF;
          img { width: 100%; max-width: 400px; transition-duration: 0.3s; }
          img:hover {
            @include transformCommon(scale(1.1));
            transition: transform 0.7s;
          }
        }
      }
      .my-masonry-grid_column {
        //padding-left: 30px; /* gutter size */
        background-clip: padding-box;
        text-align: center;
      }

      /* Style your items */
      .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
        background: grey;
        margin-bottom: 30px;
      }
    }
    @keyframes bgText {
      0% {width: 0;}
      100% {width: 100%;}
    }
    @-webkit-keyframes bgText {
      0% {width: 0;}
      100% {width: 100%;}
    }
    .tit {
      position:absolute;
      bottom: -20px;
      right:0;
      font-family: 'Titillium Web', sans-serif;
      font-weight: 600;
      font-size: 60px;
      line-height: 91px;
      text-align: right;
      color: #6C7DFC;

      &:before {
        content:'';
        position:absolute;
        bottom:0;
        right:0;
        width:100px;
        height:4px;
        background-color: #6C7DFC;;
      }
    }
  }
}

// Middle Device Ver
@media screen and (max-width: 1500px) {
  .investment {
    .section-body {
      .section-content-box {
        .sliding-comment-title-box { margin:0 150px; }
        .effect-comment-box { margin: 0 150px 137px }
      }
    }
  }
}

// Mobile Ver
@media screen and (max-width: 1200px) {
  .investment {
    //position: relative;
    .section-body {
      .section-content-box {
        .section-title {
          width: 95%;
          margin: -120px auto 80px;
          p span:first-child { font-size: 32px; line-height: 32px }
          p span:last-child  {
            margin-top: 2px; padding: 0 20px;
            font-size: 16px; box-sizing: border-box;
          }
        }
        .section-content .sliding-comment-title-box {
          width: 95%;
          margin: 0 auto;
          .moving-txt-box {
            font-size: 20px;
          }
        }
        .section-content .comment-title-box {
          padding: 50px 0;
          .effect-comment-box {
            width: 95%;
            margin: 0 auto 137px;
            font-size: 20px;
            .cr_lavender {
              font-size: 26px; font-weight: 700;
            }
            &.show-pc { display: none }
            &.show-mobile {
              display: flex;
              margin: 0 auto 50px;
              .title-bar { height: 60px; margin-right: 15px; }
              & > span.box {
                display: flex;
                flex-direction: column;
                text-align: left;
                .cr_lavender { margin-left: 0 }
              }
            }
          }
          img.show-mobile {
            width: initial; height: initial; object-fit: none;
            max-width: none; max-height: none;
            margin: 0 auto;
          }
        }
        .section-content .title-and-banner-box {
          .creator-profitable-experience-space {
            width: 95%;
            margin: 0 auto;
            p {
              ont-family: "Noto Sans KR", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 200%;
              color: #FFFFFF;
            }
            > div {
              p {
                font-size: 22px;
                font-weight: 700;
                color: #FFF;
              }
              .cr_lavender {
                font-size: 26px;
                font-weight: 700;
                color: #3FD6F5;
              }
            }
          }
        }
      }
      .section-content-box.section-name-blockchain {
        .industrial-technology-4th {
          .content-list .content {

            img {width: 25%}
            p {
              font-size: 14px;
            }
          }
        }
        .industrial-technology-4th {
          width: 95%; margin: 0 auto;
          .title { width: 100%; border-radius: 10px; }
          .content-list {
            flex-direction: column;
            .content {
              width: 100%;
              margin-top: 15px;
              &:first-child { margin-top: 0; }
            }
          }
        }
        .card-list {
          flex-direction: column;
          height: auto;
          margin-top: 30px;
        }
        .card-list .card {
          position: relative;
          width: 95%; height: auto;
          margin: 15px auto 0; padding: 0;
          border: none; box-shadow: none;
          .text-wrap div p.text { font-size: 18px }
          img.show-mobile { width: 100%; height: auto; }
        }
      }
      .section-content-box.section-name-contents {
        .section-title { width: 95%; margin: 0 auto; }
        .section-content {
          .masonry-grid { width: 95%; margin: 30px auto 0; gap: 20px }
        }
      }
    }
    .section-1 {
      width: 100%; height: 100vh;
      background-image: url("../assets/images/investment-section-1-background-mo.jpg");
      background-size: cover; background-repeat: no-repeat; background-position: center; position: relative;
    }
  }
}

@media screen and (max-width: 400px) {
  .investment {
    .text-wrap {
      .title { font-size: 18px; }
      .text { font-size: 13px; }
    }
  }
}