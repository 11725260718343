//=============================
//=== music ===
//=============================

.music {
  position: relative;
  padding-bottom: 180px;
  box-sizing: border-box;

  .section-1 {
    height: 100vh;
    position: relative;
    padding-top: 80px;
    box-sizing: border-box;

    .background-video {
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;

      video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .overlay {
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      opacity: 0.3;
      z-index: 1;
    }
  }

  .section-2 {
    margin-top: -150px;
    position: relative;
    z-index: 2;

    p {
      font-family: "Titillium Web";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 100%;
      text-align: center;
      color: #333333;
      margin-bottom: 50px;
    }

    .ost-list {
      width: 1085px;
      margin: 0 auto;

      .ost {
        width: 196.2px;
        height: 193px;
        position: relative;
        border-radius: 5px;
        display: inline-block;
        margin: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }

        .ost-overlay {
          position: absolute;
          width: 196.2px;
          height: 193px;
          left: 0px;
          top: 0px;
          background: rgba(0, 0, 0, 0.7);
          padding: 49px 0 0 0;
          display: none;
          box-sizing: border-box;
          border-radius: 5px;
          transition: all 1s ease-out;
          overflow: hidden;

          .genre {
            font-family: "Noto Sans KR";
            color: #FFFFFF;
            font-size: 14px;
            line-height: 21px;
            margin: 0px auto 9px;
            width: 64px;
            height: 21px;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 30px;
            text-align: center;
          }

          .title {
            font-family: "Noto Sans KR";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 180%;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }

      .ost:hover {

        .ost-overlay {
          display: block;
          .text-motion {
              animation:textMotion .3s ease-out both;
              -webkit-animation:textMotion .3s ease-out both;
          }
          // .genre {
          //   animation:textMotion .6s ease-out both;
          //   -webkit-animation:textMotion .6s ease-out both;
          // }
          // .title {
          //   animation:textMotion .6s ease-out both;
          //   -webkit-animation:textMotion .6s ease-out both;
          // }
        }
      }
    }
  }

  @keyframes textMotion {
    0% {opacity: 0;transform: translateY(100%);}
    100% {opacity: 1;transform: translateX(0);}
  }
  @-webkit-keyframes textMotion {
    0% {opacity: 0;transform: translateY(100%);}
    100% {opacity: 1;transform: translateX(0);}
  }
  .section-3 {
    width: 1063px;
    margin: 120px auto 0px;

    & > p {
      font-family: "Titillium Web";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 100%;
      text-align: center;
      color: #333333;
      margin-bottom: 60px;
    }

    & > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .album {
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        margin-bottom: 20px;

        & > div:last-child {
          position: absolute;
          left: 32px;
          bottom: 32px;

          p:nth-child(1) {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 100%;
            color: #FFFFFF;
          }

          p:nth-child(2) {
            font-family: "ONE Mobile Light";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            color: #FFFFFF;
            margin-top: 12px;
          }
        }

        .image-1 {
          width: 520px;
          height: 350px;
          border-radius: 10px;
          //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
          //background-size: contain;
          //background-repeat: no-repeat;
          //background-position: center;
        }

        .image-2 {
          width: 520px;
          height: 350px;
          border-radius: 10px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url("../assets/music/artist-2.jpg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        .image-1:hover,
        .image-2:hover {
          transform:scale(1.1);             /*  default */
          -webkit-transform:scale(1.1);     /*  크롬 */
          -moz-transform:scale(1.1);       /* FireFox */
          -o-transform:scale(1.1);           /* Opera */
          transition: transform 0.7s;
        }

        //img {
        //  width: 520px;
        //  height: 350px;
        //  border-radius: 10px;
        //  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url(image.png);
        //}
      }
    }
  }

  .section-4 {
    width: 1157px;
    margin: 120px auto 0px;
    padding-left: 90px;

    p {
      font-family: "Titillium Web";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 100%;
      text-align: center;
      color: #333333;
      margin-bottom: 60px;
      margin-left: -90px;
    }

    .albums {

      .album{
        width: 379px;
        height: 300px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        border-radius: 10px;

        img {
          width: 300px;
          height: 300px;
          border-radius: 10px;
        }

        img:hover {
          transform:scale(1.1);             /*  default */
          -webkit-transform:scale(1.1);     /*  크롬 */
          -moz-transform:scale(1.1);       /* FireFox */
          -o-transform:scale(1.1);           /* Opera */
          transition: transform 0.7s;
        }
      }

      .info {
        width: 200px;
        //height: 200px;
        top: 20px;
        left: 178px;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(8px);
        border-radius: 10px;
        padding-top: 34px;
        padding-left: 20px;
        padding-bottom: 34px;
        position: absolute;
        box-sizing: border-box;
        text-align: left;

        .info-1 {
          font-family: "ONE Mobile Light";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: #333333;
        }

        .info-2 {
          font-family: "ONE Mobile Light";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #333333;
          margin-top: 8px;
        }

        .info-3 {
          font-family: "ONE Mobile Light";
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 150%;
          color: #BCBFC0;
          margin-top: 4px;
        }

        .info-4 {
          width: 33px;
          height: 13px;
          border: 1px solid #3FD6F5;
          border-radius: 29px;
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          line-height: 100%;
          color: #3FD6F5;
          text-align: center;
          box-sizing: border-box;
          padding-top: 2px;
          margin-top: 12px;
        }

        .info-5 {
          font-family: "ONE Mobile Light";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #333333;
          margin-top: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .music {
    position: relative;
    padding-bottom: 180px;
    box-sizing: border-box;

    .section-1 {
      height: 100vh;
      position: relative;
      padding-top: 80px;
      box-sizing: border-box;

      .background-video {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        video {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
        }
      }

      .overlay {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: black;
        opacity: 0.3;
        z-index: 1;
      }
    }

    .section-2 {
      margin-top: -150px;
      position: relative;
      z-index: 2;

      .menu-bar {
        width: 320px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 32px;
        position: relative;
        z-index: 2;

        a {
          text-decoration: none;

          & > div {
            text-align: center;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            flex-grow: 0;
            font-family: 'Noto Sans KR';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            color: #555555;
            flex: none;
            order: 0;
            flex-grow: 0;
            padding: 3px 10px;
            box-sizing: border-box;
            cursor: pointer;
          }

          & > div.checked {
            background: #3FD6F5;
            color: #FFFFFF;
          }
        }
      }

      p {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 100%;
        text-align: center;
        color: #333333;
        margin-bottom: 32px;
      }

      .ost-list {
        width: 100%;
        margin: 0 auto;
        text-align: center;

        .ost {
          width: 160px;
          height: 160px;
          position: relative;
          border-radius: 5px;
          display: inline-block;
          margin: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .ost-overlay {
            position: absolute;
            width: 160px;
            height: 160px;
            left: 0px;
            top: 0px;
            background: rgba(0, 0, 0, 0.7);
            padding: 49px 0 0 0;
            display: none;
            box-sizing: border-box;
            border-radius: 5px;

            .genre {
              font-family: "Noto Sans KR";
              color: #FFFFFF;
              font-size: 14px;
              line-height: 180%;
              margin: 0px auto 9px;
              width: 64px;
              height: 21px;
              background: rgba(0, 0, 0, 0.7);
              border-radius: 30px;
              text-align: center;
            }

            .title {
              font-family: "Noto Sans KR";
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 180%;
              color: #FFFFFF;
              text-align: center;
            }
          }
        }

        .ost:hover {

          .ost-overlay {
            display: block;
          }
        }
      }
    }

    .section-3 {
      width: 100%;
      margin: 60px auto 0px;

      & > p {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 100%;
        text-align: center;
        color: #333333;
        margin-bottom: 32px;
      }

      & > div {
        width: 100%;
        display: flex;
        justify-content: center;

        .album {
          overflow: hidden;
          position: relative;
          border-radius: 10px;
          margin: 0px 5px;

          & > div:last-child {
            position: relative;
            left: initial;
            bottom: initial;

            p:nth-child(1) {
              font-family: 'Titillium Web';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 100%;
              color: #333333;
              margin-top: 8px;
            }

            p:nth-child(2) {
              font-family: "ONE Mobile Light";
              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 180%;
              color: #BCBFC0;
              margin-top: 4px;
            }
          }

          .image-1 {
            width: 159px;
            height: 159px;
            border-radius: 10px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url("../assets/music/artist-1.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          .image-2 {
            width: 159px;
            height: 159px;
            border-radius: 10px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url("../assets/music/artist-2.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          .image-1:hover,
          .image-2:hover {
            transform:scale(1.1);             /*  default */
            -webkit-transform:scale(1.1);     /*  크롬 */
            -moz-transform:scale(1.1);       /* FireFox */
            -o-transform:scale(1.1);           /* Opera */
            transition: transform 0.7s;
          }

          //img {
          //  width: 520px;
          //  height: 350px;
          //  border-radius: 10px;
          //  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url(image.png);
          //}
        }
      }
    }

    .section-4 {
      width: 100%;
      margin: 60px auto 0px;
      padding: 0px;
      box-sizing: border-box;

      p {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 100%;
        text-align: center;
        color: #333333;
        margin-bottom: 32px;
        margin-left: 0px;
      }

      .albums {
        text-align: center;

        .album{
          width: 159px;
          height: auto;
          position: relative;
          display: inline-block;
          overflow: initial;
          border-radius: 10px;
          margin: 10px;

          img {
            width: 159px;
            height: 159px;
            border-radius: 10px;
          }

          img:hover {
            transform:scale(1.1);             /*  default */
            -webkit-transform:scale(1.1);     /*  크롬 */
            -moz-transform:scale(1.1);       /* FireFox */
            -o-transform:scale(1.1);           /* Opera */
            transition: transform 0.7s;
          }
        }

        .info {
          width: initial;
          //height: 200px;
          top: initial;
          left: initial;
          background: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(8px);
          border-radius: 10px;
          padding-top: 0px;
          padding-left: 0px;
          padding-bottom: 0px;
          position: relative;
          box-sizing: border-box;
          text-align: left;

          .info-1 {
            font-family: "Noto Sans KR", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #333333;
          }

          .info-2 {
            font-family: "Noto Sans KR", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #333333;
            margin-top: 8px;
          }

          .info-3 {
            font-family: "Noto Sans KR", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 9px;
            line-height: 150%;
            color: #BCBFC0;
            margin-top: 4px;
          }

          .info-4 {
            width: 33px;
            height: 13px;
            border: 1px solid #3FD6F5;
            border-radius: 29px;
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 400;
            font-size: 9px;
            line-height: 100%;
            color: #3FD6F5;
            text-align: center;
            box-sizing: border-box;
            padding-top: 2px;
            margin-top: 12px;
          }

          .info-5 {
            font-family: "Noto Sans KR", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #333333;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
