//=============================
//=== Contact ===
//=============================

.contact {
  position: relative;
  padding-bottom: 145px;
  box-sizing: border-box;

  .section-1 {
    width: 100%;
    height: 100vh;
    position: relative;
    background: url('../assets/images/contact-section-1-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;

    .tab-wrap {
      width: calc(100% - 136px);
      height: 131px;
      border-bottom: 2px solid #f4f4f4;
      border-top-left-radius: 26px;
      border-top-right-radius: 26px;
      position: absolute;
      background: #ffffff;
      left: 0px;
      right: 0px;
      bottom: 80px;
      margin: 0 auto;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      .tab {
        width: 100px;
        height: 130px;
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130px;
        color: #333333;
        text-align: center;
        margin: 0px 50px;
        cursor: pointer;
      }

      .tab.selected {
        color: #3fd6f5;
        border-bottom: 2px solid #3fd6f5;
      }
    }
  }

  .section-2 {
    width: 1320px;
    margin: -40px auto 0px;
    position: relative;

    .map-wrap {
      position: relative;

      .address-wrap {
        position: absolute;
        width: 588px;
        height: 318px;
        background: #1e2021;
        border-radius: 10px;
        left: -100px;
        top: 180px;
        z-index: 2;
        box-sizing: border-box;
        padding-left: 60px;
        padding-top: 56px;
        padding-right: 50px;

        .flex-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .tel {
            margin-top: 0px;
          }
        }

        .title {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
          color: #ffffff;
        }

        .description {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          margin-top: 50px;
        }

        .tel {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: #ffffff;
          margin-top: 32px;
          span {
            margin: auto 10px;
            display: inline-block;
            width: 1px;
            height: 15px;
            background-color: #fff;
          }
        }
        .email {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: #ffffff;
          margin-top: 32px;
        }
      }

      .map {
        width: 100%;
        height: 660px;
        background-color: #92cfde;
      }
    }
  }

  .section-3 {
    text-align: center;

    .traffic-wrap {
      margin-top: 140px;

      .title {
        display: flex;
        justify-content: center;
        margin-bottom: 38px;

        p {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #333333;
          margin-left: 24px;
        }
      }

      .content {
        width: 1061px;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .contact {
    position: relative;
    padding-bottom: 145px;
    box-sizing: border-box;

    .section-1 {
      width: 100%;
      height: 100vh;
      position: relative;
      background: url('../assets/images/contact-section-1-background-mo.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      .tab-wrap {
        width: initial;
        height: auto;
        border-bottom: 0px;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        position: absolute;
        background: #ffffff;
        left: 0px;
        right: 0px;
        bottom: 60px;
        margin: 0 auto;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .tab {
          width: 90px;
          height: 52px;
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 52px;
          color: #333333;
          text-align: center;
          padding: 0px 8px;
          margin: 0px 6px;
          box-sizing: border-box;
          cursor: pointer;
          background: #f4f4f4;
          border-radius: 25px;
          margin-bottom: 8px;
        }

        .tab.selected {
          color: #ffffff;
          background: #3fd6f5;
          border-bottom: 0px;
        }
      }
    }

    .section-2 {
      width: 100%;
      margin: -40px auto 0px;
      position: relative;

      .map-wrap {
        position: relative;
        box-sizing: border-box;

        .address-wrap {
          position: relative;
          width: 100%;
          height: 260px;
          background: #1e2021;
          border-radius: 10px;
          left: 0px;
          top: 0px;
          z-index: -1;
          box-sizing: border-box;
          padding-left: 20px;
          padding-top: 65px;
          margin-top: -40px;

          .title {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 44px;
            color: #ffffff;
          }

          .description {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 28px;
            color: #ffffff;
            margin-top: 20px;
          }

          .tel {
            font-size: 12px;
            margin-top: 24px;
          }
          .email {
            font-size: 12px;
            margin-top: 24px;
          }
        }

        .map {
          width: calc(100vw - 20px);
          height: calc(100vw - 20px);
          margin: 0 auto;
          background-color: #92cfde;
          border-radius: 10px;
        }
      }
    }

    .section-3 {
      text-align: center;

      .traffic-wrap {
        margin-top: 60px;

        .title {
          display: flex;
          justify-content: center;
          margin-bottom: 38px;

          p {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;
            color: #333333;
            margin-left: 24px;
          }
        }

        .content {
          width: 320px;
          margin: 0 auto;
        }
      }
    }
  }
}
