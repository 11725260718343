//=============================
//=== Platform ===
//=============================

.platform {
  padding-bottom: 80px;
  box-sizing: border-box;
  position: relative;

  .section-1 {
    width: 100%;
    height: 100vh;
    background: url("../assets/platform/platform-section-1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  .section-2 {
    margin: -120px auto 0px;
    width: 1060px;
    z-index: 2;
    position: relative;

    .title-wrap {
      display: flex;

      .title {
        width: 522px;
        height: 50px;
        border-radius: 18px;
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 180%;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 16px;
        background: #6C7DFC;
        cursor: pointer;
        opacity: 0.6;
      }

      .title.selected {
        opacity: initial;
      }

      .title:first-child {
        margin-right: 16px;
      }
    }

    .content-wrap {
      display: flex;
      cursor: pointer;

      & > div {
        width: 522px;
        height: 150px;
        background: #FFFFFF;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        display: flex;
        align-items: center;
        border: 2px solid #6C7DFC;
        opacity: 0.6;

        p {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 180%;
          color: #333333;
        }
      }

      & > div.selected {
        opacity: initial;
      }

      & > div:first-child {
        padding-left: 24px;
        margin-right: 18px;
        box-sizing: border-box;

        img {
          margin-right: 19px;
        }
      }

      & > div:last-child {
        padding-left: 34px;
        box-sizing: border-box;

        img {
          margin-right: 25px;
        }
      }
    }
  }

  .section-3 {
    padding-left: 116px;
    box-sizing: border-box;
    display: flex;
    margin-top: 187px;

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 110px;
      line-height: 100%;
      color: #333333;
      margin-right: 60px;
    }

    .description {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 150%;
      color: #333333;
    }

    span {
      color: #6C7DFC;
    }
  }

  .ellifun-section-1 {
    width: 100%;
    height: 990px;
    background-image: url("../assets/platform/ellifun-section-1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 32px;
  }

  .ellifun-section-2 {
    width: 100%;
    height: auto;
    min-height: 1080px;
    padding: 260px 0px 0px;
    box-sizing: border-box;
    text-align: right;

    .text-wrap {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: #333333;
      text-align: right;
      margin-right: 10vw;
      margin-bottom: 94px;

      p:nth-child(1) {
        font-size: 32px;
        line-height: 150%;
      }

      p:nth-child(2) {
        font-size: 48px;
        line-height: 150%;

        span {
          color: #6C7DFC;
        }
      }
    }

    .image-wrap {
      display: flex;

      img {
        width: calc((100vw - 40px) / 3);
        height: auto;
        margin-right: 20px;
        border-radius: 10px;
      }

      img:last-child {
        margin-right: 0px;
      }
    }
  }

  .ellifun-section-3 {
    width: 100%;
    height: 990px;
    margin-top: 15vh;
    display: flex;
    align-items: start;
    justify-content: center;
    box-sizing: border-box;
    position: relative;

    .background {
      width: 100%;
      height: 60%;
      left: 0;
      bottom: 0;
      position: absolute;
      background: #6C7DFC;
    }

    .text-wrap {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 600;
      color: #333333;
      margin-right: 148px;
      margin-top: 70px;
      z-index: 1;

      p:nth-child(1) {
        line-height: 150%;
        font-size: 32px;
      }

      p:nth-child(2) {
        line-height: 150%;
        font-size: 48px;

        span {
          color: #6C7DFC;
        }
      }
    }

    img {
      width: 560px;
      height: 700px;
      object-fit: cover;
      border-radius: 10px;
      z-index: 1;
    }
  }

  .ellifun-section-4 {
    margin-top: 81px;
    text-align: center;

    p:nth-child(1) {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 200%;
      color: #333333;
    }

    p:nth-child(2) {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 200%;
      color: #6C7DFC;
    }
  }

  .ellispace-section-1 {
    width: 100%;
    height: 990px;
    background-image: url("../assets/platform/ellispace-section-1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 32px;
  }

  .ellispace-section-2 {
    padding: 137px 0px;
    box-sizing: border-box;
    text-align: center;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      max-width: 1092px;
      max-height: 883px;
      margin: 0 auto;
    }
  }

  .ellispace-section-3 {
    width: 100%;
    height: 990px;
    background-image: url("../assets/platform/ellispace-section-3-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    text-align: right;
    padding-top: 260px;
    padding-right: 10vw;

    p {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 150%;
      color: #FFFFFF;

      span {
        font-size: 48px;
      }
    }
  }

  .ellispace-section-4 {
    width: 100%;
    padding-top: 260px;
    padding-bottom: 80px;
    box-sizing: border-box;

    .text-wrap {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: #333333;
      text-align: left;
      margin-left: 10vw;
      margin-bottom: 94px;

      p:nth-child(1) {
        font-size: 32px;
        line-height: 150%;
      }

      p:nth-child(2) {
        font-size: 48px;
        line-height: 150%;

        span {
          color: #6C7DFC;
        }
      }
    }

    .image-wrap {
      display: flex;

      img {
        width: calc((100vw - 40px) / 3);
        height: auto;
        margin-right: 20px;
        border-radius: 10px;
      }

      img:last-child {
        margin-right: 0px;
      }
    }
  }

  .back-button {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .platform {
    padding-bottom: 80px;
    box-sizing: border-box;
    position: relative;

    .section-1 {
      width: 100%;
      height: 100vh;
      background: url("../assets/platform/platform-section-1-background.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
    }

    .section-2 {
      margin: -160px auto 0px;
      width: 100%;
      z-index: 2;
      position: relative;
      box-sizing: border-box;
      padding: 0px 10px;

      .title-wrap {
        display: flex;

        .title {
          width: 100%;
          height: 46px;
          border-radius: 4px;
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 180%;
          text-align: center;
          color: #FFFFFF;
          margin-bottom: 16px;
          background: #6C7DFC;
          cursor: pointer;
          opacity: 0.6;
        }

        .title.selected {
          opacity: initial;
        }

        .title:first-child {
          margin-right: 16px;
        }
      }

      .content-wrap {
        display: flex;
        cursor: pointer;

        & > div {
          width: 100%;
          height: 98px;
          background: #FFFFFF;
          box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.12);
          border-radius: 10px;
          align-items: center;
          border: 2px solid #6C7DFC;
          opacity: 0.6;
          display: none;
          padding-top: 20px;

          p {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            color: #333333;

            b {
              font-size: 22px;
            }
          }
        }

        & > div.selected {
          opacity: initial;
          display: block;
        }

        & > div:first-child {
          padding-left: 10px;
          margin-right: 0px;
          box-sizing: border-box;

          img {
            margin-right: 19px;
          }
        }

        & > div:last-child {
          padding-left: 10px;
          box-sizing: border-box;

          img {
            margin-right: 25px;
          }
        }
      }
    }

    .section-3 {
      padding-left: 10px;
      box-sizing: border-box;
      display: block;
      margin-top: 127px;

      .title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 100%;
        color: #333333;
        margin-right: 60px;
      }

      .description {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #333333;
        margin-top: 12px;
      }

      span {
        color: #6C7DFC;
      }
    }

    .ellifun-section-1 {
      width: 100%;
      height: 640px;
      background-image: url("../assets/platform/ellifun-section-1-background.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 32px;
    }

    .ellifun-section-2 {
      width: 100%;
      height: auto;
      min-height: initial;
      padding: 160px 0px 0px;
      box-sizing: border-box;
      text-align: right;

      .text-wrap {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        color: #333333;
        text-align: right;
        margin-right: 10vw;
        margin-bottom: 94px;

        p:nth-child(1) {
          font-size: 18px;
          line-height: 150%;
        }

        p:nth-child(2) {
          font-size: 22px;
          line-height: 150%;

          span {
            color: #6C7DFC;
          }
        }
      }

      .image-wrap {
        display: block;
        text-align: center;

        img {
          //width: calc((100vw - 40px) / 3);
          width: calc(100% - 20px);
          height: auto;
          margin: 0;
          border-radius: 10px;
        }

        img:last-child {
          margin-right: 0px;
        }
      }
    }

    .ellifun-section-3 {
      width: 100%;
      height: auto;
      margin-top: 15vh;
      display: block;
      align-items: start;
      justify-content: center;
      box-sizing: border-box;
      position: relative;
      text-align: center;

      .background {
        width: 100%;
        height: 60%;
        left: 0;
        bottom: -45px;
        position: absolute;
        background: #6C7DFC;
      }

      .text-wrap {
        width: 320px;
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 600;
        color: #333333;
        margin-top: 70px;
        margin: 70px auto 0;
        z-index: 1;
        text-align: left;
        padding-left: 10px;

        p:nth-child(1) {
          line-height: 150%;
          font-size: 18px;
        }

        p:nth-child(2) {
          line-height: 150%;
          font-size: 22px;

          span {
            color: #6C7DFC;
          }
        }
      }

      img {
        width: 320px;
        height: 401px;
        object-fit: cover;
        border-radius: 10px;
        z-index: 1;
        position: relative;
      }
    }

    .ellifun-section-4 {
      margin-top: 83px;
      text-align: center;
      padding: 0px 20px;
      box-sizing: border-box;

      p:nth-child(1) {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 200%;
        color: #333333;
      }

      p:nth-child(2) {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 200%;
        color: #6C7DFC;
      }
    }

    .ellispace-section-1 {
      width: 100%;
      height: 640px;
      background-image: url("../assets/platform/ellispace-section-1-background.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 32px;
    }

    .ellispace-section-2 {
      padding: 0px;
      box-sizing: border-box;
      text-align: center;

      img {
        width: initial;
        height: initial;
        object-fit: none;
        max-width: none;
        max-height: none;
        margin: 0 auto;
      }
    }

    .ellispace-section-3 {
      width: 100%;
      height: 700px;
      background-image: url("../assets/platform/ellispace-section-3-background.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-sizing: border-box;
      text-align: right;
      padding-top: 160px;
      padding-right: 5vw;

      p {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 150%;
        color: #FFFFFF;

        span {
          font-size: 22px;
        }
      }
    }

    .ellispace-section-4 {
      width: 100%;
      padding-top: 160px;
      padding-bottom: 80px;
      box-sizing: border-box;

      .text-wrap {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        color: #333333;
        text-align: left;
        margin-left: 10vw;
        margin-bottom: 94px;

        p:nth-child(1) {
          font-size: 18px;
          line-height: 150%;
        }

        p:nth-child(2) {
          font-size: 22px;
          line-height: 150%;

          span {
            color: #6C7DFC;
          }
        }
      }

      .image-wrap {
        display: block;
        text-align: center;

        img {
          width: calc(100% - 20px);
          height: auto;
          margin: 0;
          border-radius: 10px;
        }

        img:last-child {
          margin-right: 0px;
        }
      }
    }

    .back-button {
      display: block;
      margin-top: 30px;
    }
  }
}