.alliance {
  position: relative;
  padding-bottom: 120px;
  box-sizing: border-box;
  overflow: hidden;

  .section-1 {
    width: 100%;
    height: 100vh;
    background: url("../assets/images/alliance-section-1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-attachment: fixed;
  }
  .section-2 {
    max-width: 1320px;
    width: calc(100% - 136px);
    margin: -120px auto 0px;
    z-index: 2;
    position: relative;

    .section-title {
      width: 100%;
      max-width: 1320px;
      height: 228px;
      background: url("../assets/images/alliance-setction-title-background.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin: 0px auto 80px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      z-index: 1;

      p:first-child {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 100%;
        color: #333333;
      }

      .sub-section {
        width:100%;
        text-align: center;

        p {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 180%;
          color: #000000;
          word-break: keep-all;
          .mo-br {
            display:none;
          }
        }
      }
    }

    .step-wrap {
      .step-area {
        display:flex;
        justify-items:flex-start;
        border-top: 2px solid #000000;
        padding:32px 0 120px;
        .step-title {
          width:190px;
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 153%;
          color: #000;
        }

        .step-cont {
          padding-top:8px;
          width:calc(100% - 190px);
          .question-title {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 137%;
            color: #000;
            word-break: keep-all;
          }
          .question-list-wrap {
            margin-top:40px;
            .item {
              &+.item {
                margin-top:20px;
              }
              &.row2 {
                display: flex;
                .row2-box {
                  flex:2;
                  &:nth-child(2) {
                    margin-left:25px;
                  }
                }
              }
              .question-sub-title {
                display: block;
                margin-bottom:12px;
                font-family: 'Noto Sans KR', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #333;
                i {
                  font-style: normal;
                  font-weight:400;
                  font-size:16px;
                  color: #FF5E5B;
                }
                span {
                  color: #BCBFC0;
                }
                &.bold {
                  font-weight:700;
                }
              }
              .question-cont {
                .radio-wrap {
                  padding-top:20px;
                  &:after {
                    content:'';
                    display:block;
                    clear: both;
                  }
                  .inp-radio {
                    float:left;
                    width:33.333%;
                    &:nth-child(n+4) {
                      margin-top:26px;
                    }
                    label {
                      position:relative;
                      display: inline-block;
                      position: relative;
                      padding-left:36px;
                      cursor: pointer;
                      font-family: 'Noto Sans KR', sans-serif;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      color: #333;
                      
                      input {
                        position:absolute;
                        width:1px;
                        height:1px;
                        padding:0;
                        margin:-1px;
                        overflow:hidden;
                        clip:rect(0, 0, 0, 0);
                        border:0;
  
                        &.selected + .custom-checkbox {
                          &:before {
                            content:'';
                            position: absolute;
                            top:50%;
                            left:3px;
                            transform: translateY(-50%);
                            width:8px;
                            height:8px;
                            background-color:#FF5E5B;
                            border-radius: 50%;
                          }
                        }
                      }
  
                      .custom-checkbox {
                        position:absolute;
                        top:50%;
                        left:0;
                        transform: translateY(-50%);
                        width:16px;
                        height:16px;
                        border-radius: 50%;
                        border:1px solid #BCBFC0;
                        box-sizing: border-box;
                      }
                      
                    }
                  }
                }

                .textarea-wrap {
                  textarea {
                    display: block;
                    padding:20px 10px;
                    width: 100%;
                    height:300px;
                    font-family: 'Noto Sans KR', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 200%;
                    color: #333;
                    border: 1px solid #BCBFC0;
                    border-radius: 10px;
                    resize: none;
                    box-sizing:border-box;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      color:#333;
                    }
                    &::-webkit-input-placeholder {
                      color:#333;
                    }
                    &:-ms-input-placeholder {
                      color:#333;
                    }
                  }
                }

                .file-wrap {
                  position: relative;
                  .file-box {
                    position: relative;
                    display: block;
                    border-bottom: 1px solid #BCBFC0;
                    padding-bottom: 18px;
                    label {
                      display: block;
                      position:relative;
                      input[type="text"] {
                        font-family: 'Noto Sans KR', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        color: #000;
                        width:calc(100% - 203px);
                        height:50px;
                        border:none;
                        padding:0 10px;
                        box-sizing: border-box;
                        
                        &:hover {
                          border:none;
                        }
                        &:active {
                          border:none;
                        }
                        &:focus {
                          border:none;
                          outline: none;
                        }
                      }
                      .upload-hidden {
                        position: absolute;
                        top:0;
                        left:0;
                        width: 1px;
                        height: 1px;
                        padding: 0;
                        margin: -1px;
                        overflow: hidden;
                        clip:rect(0,0,0,0);
                        border: 0;
                      }
                      .file-button {
                        position: absolute;
                        width: 183px;
                        height: 46px;
                        bottom: 0;
                        right: 0;
                        background-color: #F4F4F4;
                        border-radius: 24px;
                        text-align: center;
                        cursor: pointer;
                        span {
                          display:inline-block;
                          font-family: 'Noto Sans KR', sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 48px;
                          color: #333;
                          padding-right:24px;
                          background: url("../assets/images/file-icon-button.png") no-repeat center right;
                        }
                      }
                    }
                  }
                  .file-caution {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 12px;
                    font-family: 'Noto Sans KR', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 200%;
                    color: #333;
                    .cr-black {
                      color: #333;
                    }
                    .cr-red {
                      color: #FF5E5B;
                    }
                  }
                }
                
                .input-text {
                  position: relative;
                  display: block;
                  border-bottom: 1px solid #BCBFC0;
                  padding:18px 0 8px;
                  input {
                    width:100%;
                    padding:0 10px;
                    font-family: 'Noto Sans KR', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #000;
                    border:none;
                    box-sizing:border-box;
                    &:focus {
                      outline: none;
                    }
                    &::placeholder {
                      color:#000;
                    }
                    &::-webkit-input-placeholder {
                      color:#000;
                    }
                    &:-ms-input-placeholder {
                      color:#000;
                    }
                  }
                }
                
                .text-caution {
                  margin-top:12px;
                  font-family: "Noto Sans KR", sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 200%;
                  color:#FF5E5B;
                }
              }
            }
            .personal-info-guide {
              margin-top:60px;
              .personal-sub-title {
                display: block;
                margin-bottom:12px;
                font-family: 'Noto Sans KR', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                color: #333;
              }
              .personal-box {
                overflow-x: hidden;
                overflow-y: auto;
                padding:32px 26px;
                height:224px;
                font-family: 'Noto Sans KR', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 200%;
                color: #333;
                word-break: keep-all;
                background: #F4F4F4;
                border-radius: 10px;
                box-sizing: border-box;
                ol {
                  padding:0;
                  margin:0;
                  li {
                    list-style: none;
                    &:after {
                      content:'';
                      display: block;
                      clear: both;
                    }
                    i {
                      float:left;
                      font-style:normal;
                    }
                    p {
                      float:left;
                      width:calc(100% - 14px);
                      padding-left:7px;
                      box-sizing: border-box;
                      &.bold {
                        font-weight: 700;
                      }
                      span {
                        padding-left:67px;
                      }
                    }
                  }
                }
              }
              .inp-checkbox {
                display: block;
                margin-top:20px;
                label {
                  position:relative;
                  display: inline-block;
                  position: relative;
                  padding-left:36px;
                  cursor: pointer;
                  font-family: 'Noto Sans KR', sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  color: #333;
                  
                  input {
                    position:absolute;
                    width:1px;
                    height:1px;
                    padding:0;
                    margin:-1px;
                    overflow:hidden;
                    clip:rect(0, 0, 0, 0);
                    border:0;

                    &.selected + .custom-checkbox {
                      &:before {
                        content:'';
                        position: absolute;
                        top:50%;
                        left:-1px;
                        transform: translateY(-50%);
                        width:16px;
                        height:16px;
                        font-size: 12px;
                        color:#fff;
                        line-height: 14px;
                        text-align: center;
                        border: 1px solid #FF5E5B;
                        background:#FF5E5B url(../assets/images/icon-checkbox.png) no-repeat center ;
                        border-radius: 5px;
                        box-sizing: border-box;
                      }
                    }
                  }
                  .custom-checkbox {
                    position:absolute;
                    top:50%;
                    left:0;
                    transform: translateY(-50%);
                    width:16px;
                    height:16px;
                    border: 1px solid #BCBFC0;
                    border-radius: 5px;
                    box-sizing: border-box;
                  }
                  
                }
              }
            }
          }
        }
      }
      .send-button {
        width: 198px;
        height: 46px;
        cursor: pointer;
        margin: 0 auto;
      }
    }

  }
  .alert-box {
    position: fixed;
    width: 388px;
    height: 218px;
    background: #F6F8F9;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    margin-top: -109px; /* Negative half of height. */
    margin-left: -194px; /* Negative half of width. */
    box-sizing: border-box;
    padding-top: 59px;
    z-index: 9;

    p {
      font-family: "Noto Sans KR", sans-serif;
      font-size: 20px;
      text-align: center;
      word-break: keep-all;
    }

    div {
      width: 130px;
      height: 42px;
      border: 1px solid #333333;
      border-radius: 24px;
      text-align: center;
      cursor: pointer;
      margin: 32px auto 0px;
      line-height: 42px;
      box-sizing: border-box;
    }
  }
}

.alliance-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}

@media screen and (max-width: 1200px) {
  .alliance {
    position: relative;
    box-sizing: border-box;

    .section-1 {
      width: 100%;
      height: 100vh;
      background: url("../assets/images/alliance-section-1-background-mo.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
    }

    .section-2 {
      max-width: initial;
      width: 100%;
      margin: -120px auto 0px;
      z-index: 2;
      padding: 0px 10px;
      box-sizing: border-box;
      position: relative;

      .section-title {
        width: 100%;
        max-width: 1320px;
        height: 121px;
        // background: url("../assets/images/press-release-setction-2-title-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        margin: 0px auto 20px;
        display: flex;
        align-items: center;
        padding-left: 21px;
        box-sizing: border-box;
        z-index: 1;

        .sub-section {
          p {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #333;
          }
        }
      }
      .step-wrap {
        .step-area {
          display:block;
          justify-items:normal;
          padding:32px 10px 120px;
          .step-title {
            width:100%;
          }
          .step-cont {
            width:100%;
          }
        }
      }
    }

  }
}
@media screen and (max-width: 768px) {
  .alliance {
    .section-2 {
      .section-title {
        .sub-section {
          p {
            .mo-br {
              display:block;
            }
          }
        }
      }
      
      .step-wrap {
        .step-area {
          display:block;
          justify-items:normal;
          padding:20px 0 80px;
          &.step-04 {
            padding-bottom:60px;
          }
          .step-title {
            width:100%;
            font-weight:bold;
            font-size: 24px;
            line-height: 150%;
          }

          .step-cont {
            padding-top:8px;
            width:100%;
            .question-title {
              font-size: 20px;
              line-height: 150%;
            }
            .question-list-wrap {
              margin-top:20px;
              .item {
                &+.item {
                  margin-top:28px;
                }
                &.row2 {
                  display: block;
                  .row2-box {
                    flex:none;
                    &:nth-child(2) {
                      margin-top:20px;
                      margin-left:0;
                    }
                  }
                }
                .question-sub-title {
                  display: block;
                  margin-bottom:15px;
                  font-family: 'Noto Sans KR', sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #333;
                  i {
                    font-style: normal;
                    font-weight:400;
                    font-size:16px;
                    color: #FF5E5B;
                  }
                }
                .question-cont {
                  .radio-wrap {
                    padding-top:0;
                    &:after {
                      content:'';
                      display:block;
                      clear: both;
                    }
                    .inp-radio {
                      float:none;
                      display: block;
                      width:100%;
                      &:nth-child(n+4) {
                        margin-top:0;
                      }
                      &+.inp-radio {
                        margin-top:24px;
                      }
                      label {
                        padding-left:28px;
                        font-size: 14px;
                        line-height: 19px;
                        color: #333;
                      }
                    }
                  }

                  .textarea-wrap {
                    textarea {
                      width: 100%;
                      height:500px;
                      font-size: 14px;
                      line-height: 200%;
                    }
                  }

                  .file-wrap {
                    position: relative;
                    .file-box {
                      position: relative;
                      display: block;
                      border-bottom: 1px solid #BCBFC0;
                      padding-bottom: 0;
                      label {
                        display: block;
                        position:relative;
                        input[type="text"] {
                          font-family: 'Noto Sans KR', sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 200%;
                          color: #000;
                          width:100%;
                          height:20px;
                          border:none;
                          padding:0 10px;
                          box-sizing: border-box;
                        }
                        .file-button {
                          display: block;
                          bottom:-95px;
                          left:0;
                          width: 100%;
                          height: 46px;
                          border-radius: 5px;
                          span {
                            font-size: 16px;
                            line-height: 46px;
                          }
                        }
                      }
                    }
                    .file-caution {
                      display: block;
                      justify-content: space-between;
                      margin-top: 8px;
                      font-size: 16px;
                      line-height: 200%;
                      span {
                        display: block;
                        font-size:14px;
                        line-height:150%;
                        &.file-possible-text {
                          text-align: right;
                          margin-bottom:74px;
                        }
                      }
                    }
                  }
                  
                  .input-text {
                    padding:0;
                    input {
                      font-size: 14px;
                      line-height: 200%;
                    }
                  }

                  .text-caution {
                    margin-top:8px;
                    font-size: 14px;
                    line-height: 150%;
                  }
                }
              }
              .personal-info-guide {
                margin-top:40px;
                .personal-sub-title {
                  margin-bottom:8px;
                  font-size: 18px;
                  line-height: 150%;
                  
                }
                .personal-box {
                  padding:28px 10px;
                  height: auto;
                  max-height:364px;
                  font-size: 14px;
                  border-radius: 5px;
                  ol {
                    li {
                      i {
                        float:left;
                        font-style:normal;
                      }
                      p {
                        float:left;
                        width:calc(100% - 12px);
                        padding-left:5px;
                        span {
                          padding-left:0;
                        }
                      }
                    }
                  }
                }
                .inp-checkbox {
                  display: block;
                  margin-top:12px;
                  label {
                    padding-left:28px;
                    font-size: 14px;
                    line-height: 19px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 420px) {
  .alliance {
    .alert-box {
      width: 95%;
      margin-left:0;
      transform: translateX(-50%);
    }
  }
}