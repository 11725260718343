//=============================
//=== Management ===
//=============================

.management {
  position: relative;

  .section-title {
    max-width: 1320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: #333333;
    width: 100%;
    height: 112px;
    margin: 0 auto 32px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 40px;
  }

  .section-1 {
    height: 100vh;
    position: relative;

    .background-video {
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;

      video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .overlay {
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      opacity: 0.3;
      z-index: 1;
    }
  }

  .section-2 {
    //width: calc(100% - 136px);
    margin: -150px auto 0px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;

    .content-wrap {
      max-width: 1321px;
      margin: 0 auto;

      .artist-title {
        background: url('../assets/images/management-artist-title-background.jpg');
      }

      .influencer-title {
        background: url('../assets/images/management-influencer-title-background.jpg');
      }

      .sportainer-title {
        background: url('../assets/images/management-sportainer-title-background.jpg');
      }

      .artists {
        width: 100%;
        margin: 0 auto 120px;
        overflow: hidden;
        padding-left: 1px;
        box-sizing: border-box;
        //text-align: center;

        .artist {
          width: 258px;
          height: 258px;
          margin: 2.5px;
          display: inline-block;
          box-sizing: border-box;
          position: relative;
          vertical-align: top;
          overflow: hidden;

          &:hover {
            img {
              transform: scale(1.1); /*  default */
              -webkit-transform: scale(1.1); /*  크롬 */
              -moz-transform: scale(1.1); /* FireFox */
              -o-transform: scale(1.1); /* Opera */
              transition: transform 0.7s;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .name {
            width: 97.72px;
            height: 43px;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 0px 10px 10px 0px;
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 43px;
            text-align: center;
            color: #ffffff;
            left: 0px;
            bottom: 15px;
            position: absolute;
          }
        }

        .artist.pointer {
          cursor: pointer;
        }
      }
    }

    .mouse-wrap {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 60px;
      text-align: center;

      .mouse-icon {
        text-align: center;
      }
    }
  }

  .section-3 {
    width: 100%;
    padding: 120px 0px 120px;
    box-sizing: border-box;
    background: #f6f8f9;
    backdrop-filter: blur(20px);
    position: relative;

    .vod-title {
      background: url('../assets/images/management-vod-title-background.jpg');
    }

    .vods {
      width: 1335px;
      margin: 0 auto;
      overflow: hidden;

      .vod {
        width: 433px;
        height: 300px;
        margin: 5.5px;
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;

        .thumbnail {
          width: 433px;
          height: 300px;
          object-fit: cover;
        }

        .play-button {
          position: absolute;
          bottom: 40px;
          right: 40px;
          width: 56px;
          height: 56px;
          background: url('../assets/images/management-vod-play.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }

      .vod:hover {
        .play-button {
          background: url('../assets/images/management-vod-play-hover.png');
        }
      }

      .vod.show-mobile {
        display: none;
      }

      .vod.show-pc {
        display: inline-block;
      }
    }

    .more-button {
      width: 163px;
      height: 48px;
      cursor: pointer;
      margin: 52px auto 0px;
    }
  }

  .section-4 {
    padding: 120px 0px 160px;
    box-sizing: border-box;

    .news-title {
      background: url('../assets/images/management-news-title-background.jpg');
    }

    .news {
      max-width: 1335px;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      padding-top: 5px;
      box-sizing: border-box;

      .news-item {
        min-height: 405px;
        width: 433px;
        min-height: 432px;
        margin: 0px 5.5px 20px;
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
        &:before {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          border: 4px solid transparent;
          width: 0;
          height: 0;
        }
        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          bottom: 0;
          right: 0;
          border: 4px solid transparent;
          width: 0;
          height: 0;
        }
        &:hover {
          &:before {
            border-top-color: #3fd6f5;
            border-right-color: #3fd6f5;
            width: 100%;
            height: 100%;
            transition: width 0.1s ease-out, height 0.1s ease-out 0.1s;
            box-sizing: border-box;
          }
          &:after {
            border-bottom-color: #3fd6f5;
            border-left-color: #3fd6f5;
            width: 100%;
            height: 100%;
            transition: border-color 0s ease-out 0.2s, width 0.1s ease-out 0.2s, height 0.1s ease-out 0.3s;
            box-sizing: border-box;
          }
          .image-wrap {
            img {
              transform: scale(1.1); /*  default */
              -webkit-transform: scale(1.1); /*  크롬 */
              -moz-transform: scale(1.1); /* FireFox */
              -o-transform: scale(1.1); /* Opera */
              transition: transform 0.7s;
            }
          }
          .title {
            font-weight: bold;
          }
          .date {
            font-weight: bold;
          }
        }

        .image-wrap {
          width: 100%;
          height: 258px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          img:hover {
            // transform:scale(1.1);             /*  default */
            // -webkit-transform:scale(1.1);     /*  크롬 */
            // -moz-transform:scale(1.1);       /* FireFox */
            // -o-transform:scale(1.1);           /* Opera */
            // transition: transform 0.7s;
          }
        }

        .title {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 180%;
          color: #333333;
          margin-top: 16px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .date {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          color: #bcbfc0;
          margin-top: 10px;
        }
      }
    }

    .more-button {
      width: 163px;
      height: 48px;
      cursor: pointer;
      margin: 52px auto 0px;
    }
  }

  .vod-modal {
    .vod-overlay {
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.8;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 2;
    }

    .vod-player {
      position: fixed;
      width: 1320px;
      height: 625px;
      top: 50%;
      left: 50%;
      margin-top: -312px; /* Negative half of height. */
      margin-left: -660px;
      z-index: 2;

      .relative-wrap {
        position: relative;

        .close-button {
          position: absolute;
          left: 50%;
          right: 50%;
          top: 32px;
          cursor: pointer;
          z-index: 2;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .management {
    position: relative;

    .section-title {
      max-width: initial;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      color: #333333;
      width: 100%;
      height: 60px;
      margin: 0 auto 32px;
      text-align: center;
      box-sizing: border-box;
      padding-top: 20px;
    }

    .section-1 {
      height: 100vh;
      position: relative;

      .background-video {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        video {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
        }
      }

      .overlay {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: black;
        opacity: 0.3;
        z-index: 1;
      }
    }

    .section-2 {
      width: calc(100% - 20px);
      margin: -150px auto 0px;
      box-sizing: border-box;
      position: relative;
      z-index: 2;

      .content-wrap {
        max-width: 1580px;
        margin: 0 auto;

        & > p {
          text-align: center;
          font-family: 'Titillium Web';
          font-size: 32px;
          margin-bottom: 32px;
          color: #333333;
        }

        .artists {
          width: 100%;
          margin: 0 auto;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: 60px;

          .artist {
            width: 110px;
            height: 110px;
            margin: 5px;
            display: block;
            box-sizing: border-box;
            position: relative;

            img {
              width: 100%;
            }

            .name {
              min-width: 44px;
              width: auto;
              padding:0 6px 0 4px;
              height: 20px;
              background: rgba(0, 0, 0, 0.6);
              border-radius: 0px 10px 10px 0px;
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              color: #ffffff;
              left: 0px;
              bottom: 6px;
              position: absolute;
              box-sizing:border-box;
            }

            //.artist-overlay {
            //  position: absolute;
            //  width: 180px;
            //  height: 180px;
            //  left: 0px;
            //  top: 0px;
            //  background: rgba(0, 0, 0, 0.7);
            //  padding: 54px 0 0 0;
            //  display: none;
            //  box-sizing: border-box;
            //  cursor: pointer;
            //
            //  .name {
            //    font-family: 'Noto Sans KR', sans-serif;
            //    font-style: normal;
            //    font-weight: 700;
            //    font-size: 24px;
            //    line-height: 180%;
            //    text-align: center;
            //    color: #FFFFFF;
            //  }
            //
            //  .link {
            //    font-family: 'Titillium Web';
            //    font-style: normal;
            //    font-weight: 600;
            //    font-size: 18px;
            //    line-height: 180%;
            //    text-align: center;
            //    color: #FFFFFF;
            //  }
            //}
          }

          //.artist:hover {
          //
          //  .artist-overlay {
          //    display: none;
          //  }
          //}
        }
      }

      .mouse-wrap {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 60px;
        text-align: center;

        .mouse-icon {
          text-align: center;
        }
      }
    }

    .section-3 {
      width: 100%;
      padding: 0px 10px 0px;
      box-sizing: border-box;
      background: #ffffff;
      backdrop-filter: blur(20px);
      position: relative;
      box-sizing: border-box;

      & > p {
        font-family: 'Titillium Web';
        font-size: 32px;
        color: #333333;
        text-align: center;
        margin-bottom: 24px;
      }

      .vods {
        width: initial;
        margin: 0 auto;
        overflow: hidden;
        text-align: center;

        .vod {
          width: 342px;
          height: 236px;
          margin: 5.5px;
          display: inline-block;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;

          .thumbnail {
            width: 342px;
            height: 236px;
            object-fit: cover;
          }

          .mobile-play-button {
            position: absolute;
            bottom: 20px;
            right: 20px;
          }
        }

        .vod:hover {
          .play-button {
            background: url('../assets/images/management-vod-play.png');
          }
        }

        //.vod.show-mobile {
        //  display: none;
        //}

        //.vod.show-pc {
        //  display: inline-block;
        //}
      }
    }

    .section-4 {
      padding: 60px 10px 160px;
      box-sizing: border-box;

      .news {
        max-width: initial;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .news-item {
          min-height: 352px;
          width: 340px;
          margin: 0px 5px 10px;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          padding: 10px;
          box-sizing: border-box;
          box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);

          .image-wrap {
            width: 100%;
            height: 210px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            img:hover {
              transform: scale(1.1); /*  default */
              -webkit-transform: scale(1.1); /*  크롬 */
              -moz-transform: scale(1.1); /* FireFox */
              -o-transform: scale(1.1); /* Opera */
              transition: transform 0.7s;
            }
          }

          .title {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #333333;
            margin-top: 16px;
          }

          .date {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 180%;
            color: #bcbfc0;
            margin-top: 10px;
          }
        }
      }

      .more-button {
        width: 163px;
        height: 48px;
        cursor: pointer;
        margin: 52px auto 0px;
      }
    }

    .vod-modal {
      .vod-overlay {
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.8;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 2;
      }

      .vod-player {
        position: fixed;
        width: 342px;
        height: 236px;
        top: 50%;
        left: 50%;
        margin-top: -118px; /* Negative half of height. */
        margin-left: -171px;
        z-index: 2;

        .relative-wrap {
          position: relative;

          .close-button {
            position: absolute;
            left: 50%;
            right: 50%;
            top: 32px;
            cursor: pointer;
            z-index: 2;
            transform: translate(-20px, 0%);
          }
        }
      }
    }
  }

  .artist-profile-modal {
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    position: fixed;
    background-color: #333333;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name {
      font-family: 'Noto Sans KR', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 180%;
      color: #ffffff;
      margin-bottom: 21px;
    }

    .profile-image {
      width: 360px;
    }

    .profile-button {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 180%;
      color: #ffffff;
      margin-top: 12px;
    }

    .close-button {
      margin-top: 32px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
