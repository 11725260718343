.header {
  width: 100%;
  height: 110px;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 99;
  transition-duration: 0.1s;
  background: #1E2021;

  .top-menu {
    height: 100%;
    padding: 0px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;

    .right {
      height: 100%;
      display: flex;
      align-items: center;

      .menu {
        margin: 0px 50px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;

        a, p {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;
          color: #FFFFFF;
          text-decoration: none;
        }

        .bar {
          width: 20px;
          height: 2px;
          background: #1E2021;
          margin: 0px auto;
          position: absolute;
          left: 0px;
          right: 0px;
          bottom: -16px;
        }
      }

      .menu:first-child {
        margin-left: 0px;
      }

      .menu:last-child {
        margin-right: 0px;
      }

      .menu.selected {

        a, p {
          color: #3FD6F5;
        }

        .bar {
          background: #3FD6F5;
        }
      }
    }
  }

  .bottom-menu {
    width: 100%;
    height: 500px;
    background: #1E2021;
    border-top: 1px solid #555555;
    display: flex;
    justify-content: center;

    .category {
      width: 187px;
      height: 100%;
      // padding-top: 50px;
      padding-top:32px;
      border-left: 1px solid #555555;
      box-sizing: border-box;

      &:last-child {
        border-right:1px solid #555;
      }

      a {
        text-decoration: none;

        &:nth-of-type(1) {
          .bold-white {
            margin-top:69px;
          }
        }
      }

      .category-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #555555;
        margin-bottom: 40px;
        padding-left: 32px;
        box-sizing: border-box;
      }

      .bold-white {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        margin-top: 32px;
        cursor: pointer;
        padding-left: 24px;
        box-sizing: border-box;
      }

      .normal-white {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #F4F4F4;
        margin-top: 20px;
        cursor: pointer;
        padding-left: 32px;
        box-sizing: border-box;

        &.mt24 {
          margin-top:24px;
        }
      }

      .bold-white.selected,
      .bold-white:hover,
      .normal-white.selected,
      .normal-white:hover {
        color: #3FD6F5;
        border-left: 1px solid #3FD6F5;
        margin-left: -1px;
      }

      &.business {
        width:561px;

        &:after {
          content:'';
          display:block;
          clear:both;
        }

        .category-title {
          text-align: center;
          padding-right: 32px;
        }

        .nav-row {
          padding-top:37px;
          float:left;
          width:33.333%;
          height:calc(100% - 55px);
          box-sizing:border-box;

          +.nav-row {
            border-left: 1px solid rgba(85, 85, 85, 1);
          }

          .bold-white {
            margin-top:0;
          }
        }

        .category-title {
          padding-bottom:31px;
          border-bottom: 1px solid rgba(85, 85, 85, 1);
          margin-bottom:0;
        }
      }
    }
  }
}

.header.void {
  background: none;

  .top-menu {

    .right {

      .menu {

        .bar {
          background: none;
        }
      }
    }
  }
}

.mobile-header {
  display: none;
}

@media screen and (max-width: 1200px) {
  .header {
    display: none;
  }

  .mobile-header {
    width: 100%;
    height: 56px;
    left: 0px;
    top: 0px;
    position: fixed;
    z-index: 99;
    //background: #1E2021;
    display: block;

    .top-menu {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 16px;
    }

    .top-menu.full {
      background: #1E2021;
    }

    .left-menu {
      width: 70vw;
      height: 100vh;
      position: fixed;
      left: 0px;
      top: 0px;
      z-index: 99;
      background: #1E2021;
      backdrop-filter: blur(40px);
      padding: 20px;
      box-sizing: border-box;
      overflow-y: scroll;

      .close-button {
        width: 30px;
        height: 30px;
        margin-bottom: 25px;
      }

      a {
        text-decoration: none;
      }

      .menu-wrap {

        .main-menu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #555555;
          padding-bottom: 10px;
          box-sizing: border-box;
          margin-bottom: 28px;

          p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            display: flex;
            align-items: center;
            color: #F4F4F4;
          }

          img {
            width: 13px;
            height: 7px;
          }
        }

        .main-menu {
          .selected {
            color: #3FD6F5;
          }
        }

        .sub-menu {
          margin-bottom: 40px;

          .white {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #F4F4F4;
            margin-bottom: 24px;
            padding-left: 8px;
            box-sizing: border-box;
          }

          .gray {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #BCBFC0;
            margin-bottom: 20px;
            padding-left: 18px;
            box-sizing: border-box;
          }

          .white.selected,
          .gray.selected {
            color: #3FD6F5;
          }

          a:last-child {
            .white,
            .gray {
              margin-bottom: 0px;
            }
          }
        }
      }

      // .menu-wrap:last-child {
      //   .main-menu {
      //     border-bottom: 0px;
      //   }
      // }
    }
  }
}