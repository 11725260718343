//=============================
//=== Management Artist ===
//=============================


.management-artist {
  padding-bottom: 120px;
  box-sizing: border-box;
  overflow: hidden;

  .section-1 {
    position: relative;

    img {
      width: 100vw;
      object-fit: cover;
    }

    .name-wrap {
      width: 768px;
      height: 118px;
      background: #000000;
      border-radius: 10px 0px 0px 10px;
      position: absolute;
      right: 0px;
      bottom: -53px;
      display: flex;
      align-items: center;

      .kor-name {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 100%;
        color: #FFFFFF;
        margin-left: 46px;
      }

      .divider {
        width: 2px;
        height: 50px;
        background: #555555;
        margin: 0px 20px;
      }

      .eng-name {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 180%;
        color: #FFFFFF;
      }
    }
  }

  .section-2 {
    max-width: 1320px;
    width: 100%;
    margin: 178px auto 0px;

    .accordion-wrap {
      width: 100%;
      margin-top: 60px;

      .top {
        width: 100%;
        height: 90px;
        background: #FFFFFF;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 0px 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .title {
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 100%;
          background: #333333;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          transition: all .3s;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      .bottom {
        width: 100%;
        padding: 0px 40px;
        box-sizing: border-box;
        background: #333333;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        margin-top: 20px;
        display: none;

        .item-wrap {
          display: flex;
          align-items: start;
          border-top: 1px solid #555555;
          padding: 60px 0px;
          box-sizing: border-box;

          .line {
            width: 5px;
            height: 28px;
            background: #FFFFFF;
            margin-right: 35px;
          }

          .title {
            width: 200px;
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 100%;
            display: flex;
            align-items: center;
            color: #FFFFFF;
          }

          .content {
            max-height: 744px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: start;

            p {
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 100%;
              color: #FFFFFF;
              margin-bottom: 20px;
              margin-right: 80px;
            }
          }
        }

        .item-wrap:first-child {
          border-top: 0px;
        }
      }
    }

    .accordion-wrap.selected {

      .top {

        .title {
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 100%;
          background: linear-gradient(115.93deg, #19DACC 18.32%, #9A7AE9 95.14%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }
      }

      .bottom {
        display: block;
      }
    }
  }

  .section-3.show-pc {
    max-width: 1320px;
    width: 100%;
    margin: 120px auto 180px;

    .image-wrap {
      display: flex;
      margin-bottom: 10px;
      .image {
        background: #D9D9D9;
      }

      .flex-wrap {
        display: flex;
        flex-direction: column;
      }

      .image-1 {
        width: 100%;
        height: auto;
        min-width: 877px;
        min-height: 877px;
        object-fit: cover;
        margin-right: 10px;
      }

      .image-2,
      .image-3,
      .image-5,
      .image-6 {
        width: 433px;
        height: 433px;
      }

      .image-2,
      .image-5 {
        margin-bottom: 10px;
      }

      .image-4 {
        width: 877px;
        height: 877px;
      }

      .image-5 {
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .management-artist {
    margin-top: 56px;
    padding-bottom: 160px;
    box-sizing: border-box;

    .section-1 {
      position: relative;
      margin-bottom: 95px;

      img {
        width: 100vw;
        object-fit: cover;
      }

      .name-wrap {
        width: 280px;
        height: 70px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;
        position: absolute;
        right: 0px;
        bottom: -35px;
        display: flex;
        align-items: center;

        .kor-name {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 100%;
          color: #FFFFFF;
          margin-left: 46px;
        }

        .divider {
          width: 2px;
          height: 23px;
          background: #555555;
          margin: 0px 20px;
        }

        .eng-name {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 180%;
          color: #FFFFFF;
        }
      }
    }

    .section-2 {
      max-width: 1320px;
      width: 100%;
      margin: 60px auto 0px;

      .accordion-wrap {
        width: 100%;
        margin-top: 32px;

        .top {
          width: 100%;
          height: 56px;
          background: #FFFFFF;
          box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          padding: 0px 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .title {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            background: #333333;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }

        .bottom {
          width: 100%;
          padding: 0px 20px;
          box-sizing: border-box;
          background: #333333;
          box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          margin-top: 12px;
          display: none;
          transition: all 1s;

          .item-wrap {
            display: block;
            align-items: start;
            border-top: 1px solid #555555;
            padding: 32px 0px;
            box-sizing: border-box;

            .line {
              width: 3px;
              height: 18px;
              background: #FFFFFF;
              margin-right: 20px;
              display: inline-block;
            }

            .title {
              width: initial;
              font-family: 'Noto Sans KR', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              line-height: 100%;
              display: inline-block;
              align-items: center;
              color: #FFFFFF;
              margin-bottom: 20px;
            }

            .content {
              max-height: initial;
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              align-content: start;

              p {
                font-family: 'Noto Sans KR', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                color: #FFFFFF;
                margin-bottom: 20px;
                margin-right: 0px;
              }
            }
          }

          .item-wrap:first-child {
            border-top: 0px;
          }
        }
      }

      .accordion-wrap.selected {

        .top {

          .title {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            background: linear-gradient(115.93deg, #19DACC 18.32%, #9A7AE9 95.14%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
          }
        }

        .bottom {
          display: block;
        }
      }
    }

    .section-3.show-mobile {
      max-width: initial;
      width: 100%;
      margin: 60px auto 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .image {
        max-width: 360px;
        max-height: 360px;
        width: 100vw;
        height: 100vw;
        margin: 5px;
        background: #555555;
      }
    }
  }
}