//=============================
//=== contents ===
//=============================

.contents {
  position: relative;
  padding-bottom: 180px;
  box-sizing: border-box;

  .section-1 {
    width: 100%;
    height: 100vh;
    background: url("../assets/images/investment-section-1-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-attachment: fixed;
  }
  .section-1_5{
    margin-top: -150px;
    position: relative;
    z-index: 2;
    .section-title{
      width: 100%;
      max-width: 1320px;
      height: 228px;
      background: url("../assets/platform/investment_S03.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      margin: 0px auto 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      z-index: 1;

      p{
        font-family: "Noto Sans KR", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 180%;
        color: white;
        word-break: keep-all;
        text-align: center;

        strong{
          font-size: 48px;
        }

      }

    }
  }
  .section-2 {

    p {
      font-family: "Titillium Web";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 100%;
      text-align: center;
      color: #333333;
      margin-bottom: 50px;
    }

    .ost-list {
      width: 960px;
      margin: 0 auto;

      .ost:not(:last-child){
        margin-right: 3.1%;
      }
      .ost {
        width: 300px;
        height: 430px;
        position: relative;
        border-radius: 5px;
        display: inline-block;

        img {
          width: 300px;
          height: 430px;
          border-radius: 5px;
        }

        .ost-overlay {
          position: absolute;
          width: 300px;
          height: 430px;
          left: 0px;
          top: 0px;
          background: rgba(0, 0, 0, 0.7);
          padding: 49px 0 0 0;
          display: none;
          box-sizing: border-box;
          border-radius: 5px;
          transition: all 1s ease-out;
          overflow: hidden;

          .genre {
            font-family: "Noto Sans KR";
            color: #FFFFFF;
            font-size: 14px;
            line-height: 21px;
            margin: 0px auto 9px;
            width: 64px;
            height: 21px;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 30px;
            text-align: center;
          }

          .title {
            font-family: "Noto Sans KR";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 180%;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }

      .ost:hover {

        .ost-overlay {
          display: block;
          .text-motion {
              animation:textMotion .3s ease-out both;
              -webkit-animation:textMotion .3s ease-out both;
          }
          // .genre {
          //   animation:textMotion .6s ease-out both;
          //   -webkit-animation:textMotion .6s ease-out both;
          // }
          // .title {
          //   animation:textMotion .6s ease-out both;
          //   -webkit-animation:textMotion .6s ease-out both;
          // }
        }
      }
    }
  }

  @keyframes textMotion {
    0% {opacity: 0;transform: translateY(100%);}
    100% {opacity: 1;transform: translateX(0);}
  }
  @-webkit-keyframes textMotion {
    0% {opacity: 0;transform: translateY(100%);}
    100% {opacity: 1;transform: translateX(0);}
  }

}

@media screen and (max-width: 1200px) {
  .contents {
    position: relative;
    padding-bottom: 180px;
    box-sizing: border-box;

    .section-1 {
      height: 100vh;
      position: relative;
      padding-top: 80px;
      box-sizing: border-box;

      .background-video {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        video {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
        }
      }

      .overlay {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: black;
        opacity: 0.3;
        z-index: 1;
      }
    }

    .section-2 {
      margin-top: -150px;
      position: relative;
      z-index: 2;

      .menu-bar {
        width: 320px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 32px;
        position: relative;
        z-index: 2;

        a {
          text-decoration: none;

          & > div {
            text-align: center;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            flex-grow: 0;
            font-family: 'Noto Sans KR';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            color: #555555;
            flex: none;
            order: 0;
            flex-grow: 0;
            padding: 3px 10px;
            box-sizing: border-box;
            cursor: pointer;
          }

          & > div.checked {
            background: #3FD6F5;
            color: #FFFFFF;
          }
        }
      }

      p {
        font-family: "Titillium Web";
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 100%;
        text-align: center;
        color: #333333;
        margin-bottom: 32px;
      }

      .ost-list {
        width: 100%;
        margin: 0 auto;
        text-align: center;

        .ost {
          width: 160px;
          height: 160px;
          position: relative;
          border-radius: 5px;
          display: inline-block;
          margin: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .ost-overlay {
            position: absolute;
            width: 160px;
            height: 160px;
            left: 0px;
            top: 0px;
            background: rgba(0, 0, 0, 0.7);
            padding: 49px 0 0 0;
            display: none;
            box-sizing: border-box;
            border-radius: 5px;

            .genre {
              font-family: "Noto Sans KR";
              color: #FFFFFF;
              font-size: 14px;
              line-height: 180%;
              margin: 0px auto 9px;
              width: 64px;
              height: 21px;
              background: rgba(0, 0, 0, 0.7);
              border-radius: 30px;
              text-align: center;
            }

            .title {
              font-family: "Noto Sans KR";
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 180%;
              color: #FFFFFF;
              text-align: center;
            }
          }
        }

        .ost:hover {

          .ost-overlay {
            display: block;
          }
        }
      }
    }

  }
}
