/* reset */
* {margin:0; padding:0;}
ol, ul {list-style:none;}
// html, body{position: relative;font-family:'Noto Sans KR',sans-serif;font-size:14px;color:#000;line-height: 1.3;height:100%;}
// html, body, div, applet, object, iframe,h1, h2, h3, h4, h5, h6, p,
// blockquote, pre,a, abbr, acronym, address, big, cite, code,del, dfn, em,
// img, ins, kbd, q, s, samp,small, strike, sub, sup, tt, var,b, i,
// center,dl, dt, dd, ol, ul, li,fieldset, form, label, legend, table, caption,
// tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure,
// figcaption, footer, header, hgroup, menu, nav, output, ruby, section,
// summary, time, mark, audio, video{margin:0;padding:0;border:0;color:inherit;}
// article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}
// body {position:relative;line-height:1;}
// ol, ul {list-style:none;}
// blockquote, q {quotes:none}
// blockquote:before, blockquote:after,
// q:before, q:after {content:'';content:none}
// table{border-collapse:collapse;border:0;table-layout:fixed;}
// th,td{word-wrap:break-word;line-height:1.3;padding-left:3px;padding-right:3px;}
// img{vertical-align:top;max-width: 100%}
// input[type=text],input[type=password], input[type=number]{max-width: 100%;font-family:'Noto Sans KR',sans-serif;}
// input[type=checkbox], input[type=radio]{vertical-align:middle;position:relative;margin:0;padding:0;}
// textarea{font-family:'Noto Sans KR',sans-serif !important;font-size:13px;color:#000;}
// #accessibility, .skip, legend, caption{position:absolute;clip:rect(0 0 0 0);width:1px;height:1px;margin:-1px;overflow:hidden;}
// button{padding:0;margin:0;border:0;background:0;line-height:normal;cursor:pointer;font-family:'Noto Sans KR',sans-serif;}
// button:focus{outline:none;}
// a{text-decoration:none;cursor:pointer;}
// a:link,a:hover,a:active{text-decoration:none;outline:none !important;color:inherit;}


.policy-container {background:#fff;}
.policy-area {padding:30px 0 50px;color:#333;text-align: left;word-break: keep-all;font-size:15px;line-height: 1.6;width:1312px;margin:0 auto;}
.policy-area .pl10 {padding-left:18px;}
.policy-area .pl25 {padding-left:34px;}
.policy-area .mt5 {margin-top:5px;}
.policy-area .mt10 {margin-top:10px;}
.policy-area h1 {position:relative;margin-bottom:50px;text-align: center;font-size:38px;font-weight: 500;}
.policy-area h1:before {content:'';position:absolute;bottom:-10px;left:0;right:0;margin:0 auto;width:50px;height:2px;background-color:#000;}
.policy-area h2 {margin-top:20px;margin-bottom:5px;font-weight:600;font-size:15px;}
.policy-area h3 {padding-left:10px;margin-top:10px;margin-bottom:5px;font-weight:600;font-size:15px;}
.policy-area h4 {padding-left:10px;margin-top:10px;margin-bottom:5px;font-weight:600;font-size:15px;}
.policy-area p,
.policy-area ul li {font-weight:400;font-size:15px;}
.policy-area ul li strong {font-weight:600;}
strong {font-weight: 600;}

@media screen and (max-width: 1312px) {
    .policy-area {width:100%;padding:30px 15px 50px;box-sizing: border-box;}
}
@media screen and (max-width: 768px) {
    .policy-area {font-size:13px;}
    .policy-area h1 {font-size:30px;}
    .policy-area h2 {font-size:13px;}
    .policy-area h3 {font-size:13px;}
    .policy-area h4 {font-size:13px;}
    .policy-area p,
    .policy-area ul li {font-size:13px;}
}