//=============================
//=== Home ===
//=============================

.home {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .content-wrap {
    width: 100%;
    height: 100vh;
    position: relative;

    .text-wrap {
      position: absolute;
      z-index: 1;
      top: 20vh;
      width: 1130px;
      height: 557px;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(8px);
      border-radius: 0px 26px 26px 0px;
      padding-top: 60px;
      padding-left: 300px;
      box-sizing: border-box;

      .title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 120px;
        line-height: 141px;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
        color: #FFFFFF;
      }

      .title-1,
      .title-2 {
        span {
          color: #3FD6F5;
        }
      }

      .title-3 {
        span {
          background: linear-gradient(110.53deg, #3FD6F5 -1.55%, #FFFFFF 104.82%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }
      }

      .divider {
        width: 98px;
        height: 2px;
        background-color: #FFFFFF;
        margin-top: 26px;
        margin-bottom: 38px;
      }

      .description {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 150%;
        color: #FFFFFF;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
      }
    }

    img {
      width: 100%;
      height: 100vh;
      &.show-mobile {
        display:none;
      }
    }
  }

  .slick-slide {
    &.slick-active {
      .title {
        opacity: 0;
        animation:homeSlideMotion .6s ease-out both;
        -webkit-animation:homeSlideMotion .6s ease-out both;
      }
      .description {
        opacity: 0;
        animation:homeSlideMotion .6s .6s ease-out both;
        -webkit-animation:homeSlideMotion .6s .6s ease-out both;
      }
      img {
        animation:homeSlideImg 5.5s .6s ease-out both;
        -webkit-animation:homeSlideImg 5.5s .6s ease-out both;
      }
    }
    img {
      transform:scale(1);             /*  default */
      -webkit-transform:scale(1);     /*  크롬 */
      -moz-transform:scale(1);       /* FireFox */
      -o-transform:scale(1);           /* Opera */
    }
  }

  // .slick-current {
  //   img {
  //     transform:scale(1.1);             /*  default */
  //     -webkit-transform:scale(1.1);     /*  크롬 */
  //     -moz-transform:scale(1.1);       /* FireFox */
  //     -o-transform:scale(1.1);           /* Opera */
  //     transition: transform 5.5s;
  //   }
  // }

  .copyright {
    font-family: "ONE Mobile Regular";
    position: absolute;
    right: 58px;
    bottom: 32px;
    font-size: 12px;
    color: #333333;
  }

  .tab-wrap {
    width: 100%;
    left: 0px;
    bottom: 80px;
    position: absolute;
    border-bottom: 2px solid rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;

    .tab {
      width: 366px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.6;
      cursor: pointer;
      &:before {
        content:'';
        position:absolute;
        bottom:-4px;
        left:0;
        width:0;
        height:4px;
        background-color: rgba(255, 255, 255, .6);
      }
    }

    .tab.selected {
      opacity: initial;
      // border-bottom: 4px solid rgba(255, 255, 255, .6);
      border-bottom:4px solid transparent;
      margin-bottom: -3px;
      position:relative;
      &:before {
        animation:homeSlidePaging 7s .1s linear both;
        -webkit-animation:homeSlidePaging 7s .1s linear both;
      }
    }
  }
}

@keyframes homeSlideMotion {
  0% {opacity: 0;transform: translateX(-100%);}
  100% {opacity: 1;transform: translateX(0);}
}
@-webkit-keyframes homeSlideMotion {
  0% {opacity: 0;transform: translateX(-100%);}
  100% {opacity: 1;transform: translateX(0);}
}
@keyframes homeSlidePaging {
  0% {width: 0;}
  100% {width: 100%;}
}
@-webkit-keyframes homeSlidePaging {
  0% {width: 0;}
  100% {width: 100%;}
}
@keyframes homeSlideImg {
  0% {
    transform:scale(1);             /*  default */
    -webkit-transform:scale(1);     /*  크롬 */
    -moz-transform:scale(1);       /* FireFox */
    -o-transform:scale(1);           /* Opera */
  }
  100% {
    transform:scale(1.1);             /*  default */
    -webkit-transform:scale(1.1);     /*  크롬 */
    -moz-transform:scale(1.1);       /* FireFox */
    -o-transform:scale(1.1);           /* Opera */
  }
}
@-webkit-keyframes homeSlideImg {
  0% {
    transform:scale(1);             /*  default */
    -webkit-transform:scale(1);     /*  크롬 */
    -moz-transform:scale(1);       /* FireFox */
    -o-transform:scale(1);           /* Opera */
  }
  100% {
    transform:scale(1.1);             /*  default */
    -webkit-transform:scale(1.1);     /*  크롬 */
    -moz-transform:scale(1.1);       /* FireFox */
    -o-transform:scale(1.1);           /* Opera */
  }
}

@media screen and (max-width: 1200px) {
  .home {

    .content-wrap {

      .text-wrap {
        top: 20vh;
        width: calc(100% - 20px);
        height: 260px;
        padding-top: 48px;
        padding-left: 24px;

        .title {
          font-size: 46px;
          line-height: 54px;
        }

        .title-3 {
          span {

          }
        }

        .divider {
          margin-top: 7px;
          margin-bottom: 17px;
        }

        .description {
          font-size: 16px;
          line-height: 140%;
        }
      }

      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        &.show-pc {
          display:none;
        }
        &.show-mobile {
          display:block;
        }
      }
    }

    .slick-slide {

      img {
        transform:scale(1);             /*  default */
        -webkit-transform:scale(1);     /*  크롬 */
        -moz-transform:scale(1);       /* FireFox */
        -o-transform:scale(1);           /* Opera */
      }
    }

    // .slick-current {
    //   img {
    //     transform:scale(1.1);             /*  default */
    //     -webkit-transform:scale(1.1);     /*  크롬 */
    //     -moz-transform:scale(1.1);       /* FireFox */
    //     -o-transform:scale(1.1);           /* Opera */
    //     transition: transform 4s;
    //   }
    // }

    .copyright {
      font-family: "ONE Mobile Regular";
      position: absolute;
      right: 10px;
      bottom: 50px;
      font-size: 11px;
      color: #BCBFC0;
    }

    .tab-wrap {
      width: 100%;
      left: 0px;
      bottom: 100px;
      position: absolute;
      border-bottom: 2px solid rgba(255, 255, 255, 0.6);
      display: flex;
      justify-content: center;

      .tab {
        width: calc((100% - 10px) / 3);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.6;
        box-sizing: border-box;
        padding: 0px 5px 12px 5px;
      }

      .tab.selected {
        opacity: initial;
        // border-bottom: 4px solid #FFFFFF;
        margin-bottom: -3px;
      }
    }
  }
}