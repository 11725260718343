//=============================
//=== Drama ===
//=============================

.drama {
  position: relative;

  .section-1 {
    height: 100vh;
    position: relative;
    padding-top: 80px;
    box-sizing: border-box;

    .background-video {
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;

      video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
      }
    }

    .overlay {
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      opacity: 0.3;
      z-index: 1;
    }
  }

  .section-2 {
    width: 1321px;
    margin: -130px auto 0px;
    padding-bottom: 180px;
    box-sizing: border-box;
    z-index: 2;

    .my-masonry-grid {
      display: -webkit-box; /* Not needed if autoprefixing */
      display: -ms-flexbox; /* Not needed if autoprefixing */
      display: flex;
      //margin-left: -30px; /* gutter size offset */
      width: auto;
      position: relative;
      z-index: 2;

      .image-wrap {
        overflow: hidden;
        background-color: #FFFFFF;

        img {
          width: 300px;
          transition-duration: 0.3s;
        }

        img:hover {
          transform:scale(1.1);             /*  default */
          -webkit-transform:scale(1.1);     /*  크롬 */
          -moz-transform:scale(1.1);       /* FireFox */
          -o-transform:scale(1.1);           /* Opera */
          transition: transform 0.7s;
        }
      }
    }
    .my-masonry-grid_column {
      //padding-left: 30px; /* gutter size */
      background-clip: padding-box;
      text-align: center;
    }

    /* Style your items */
    .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
      background: grey;
      margin-bottom: 30px;
    }
  }

  .drama-wrap {
    display: flex;
    position: relative;
    z-index: 2;
    align-items: center;
    margin-bottom: 200px;

    .text-wrap {
      width: 800px;
      height: 714px;
      background: #F4F4F4;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-top: 32px;
      padding-left: 66px;
      box-sizing: border-box;

      .title {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 180%;
        color: #000000;
        margin-bottom: 20px;
      }

      .staff-wrap {
        display: flex;
        flex-wrap: wrap;
        padding-right: 70px;
        box-sizing: border-box;

        p {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: #9B9B9B;

          span {
            font-weight: 700;
            color: #9B9B9B;
            margin-right: 16px;
          }
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background: #555555;
        margin-top: 20px;
        margin-bottom: 32px;
      }

      .content {
        font-family: 'Noto Sans KR', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #333333;
      }
    }

    img {
      width: 580px;
      height: 787px;
      box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      z-index: 1;
    }
  }

  .drama-wrap:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 1480px) {
  .drama {
    position: relative;

    .section-2 {
      width: 100%;
      margin: -130px auto 0px;
      padding-bottom: 180px;
      box-sizing: border-box;
      z-index: 2;

      .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        //margin-left: -30px; /* gutter size offset */
        width: auto;
        position: relative;
        z-index: 2;

        .image-wrap {
          overflow: hidden;
          background-color: #FFFFFF;

          img {
            width: 240px;
            transition-duration: 0.3s;
          }

          img:hover {
            transform:scale(1.1);             /*  default */
            -webkit-transform:scale(1.1);     /*  크롬 */
            -moz-transform:scale(1.1);       /* FireFox */
            -o-transform:scale(1.1);           /* Opera */
            transition: transform 0.7s;
          }
        }
      }
      .my-masonry-grid_column {
        //padding-left: 30px; /* gutter size */
        background-clip: padding-box;
        text-align: center;
      }

      /* Style your items */
      .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
        background: grey;
        margin-bottom: 30px;
      }

    }
  }
}

@media screen and (max-width: 1200px) {

  .drama {
    position: relative;

    .section-1 {
      height: 100vh;
      position: relative;
      padding-top: 80px;
      box-sizing: border-box;

      .background-video {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        video {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
        }
      }

      .overlay {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: black;
        opacity: 0.3;
        z-index: 1;
      }
    }

    .section-2 {
      width: 100%;
      margin: -150px auto 0px;
      padding-bottom: 180px;
      box-sizing: border-box;
      z-index: 2;

      .menu-bar {
        width: 320px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 32px;
        position: relative;
        z-index: 2;

        a {
          text-decoration: none;

          & > div {
            text-align: center;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            flex-grow: 0;
            font-family: 'Noto Sans KR';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            color: #555555;
            flex: none;
            order: 0;
            flex-grow: 0;
            padding: 3px 10px;
            box-sizing: border-box;
            cursor: pointer;
          }

          & > div.checked {
            background: #3FD6F5;
            color: #FFFFFF;
          }
        }
      }

      .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        //margin-left: -30px; /* gutter size offset */
        width: auto;
        position: relative;
        z-index: 2;

        .image-wrap {
          overflow: hidden;
          background-color: #FFFFFF;

          img {
            width: 160px;
            transition-duration: 0.3s;
          }

          img:hover {
            transform:scale(1.1);             /*  default */
            -webkit-transform:scale(1.1);     /*  크롬 */
            -moz-transform:scale(1.1);       /* FireFox */
            -o-transform:scale(1.1);           /* Opera */
            transition: transform 0.7s;
          }
        }
      }
      .my-masonry-grid_column {
        //padding-left: 30px; /* gutter size */
        background-clip: padding-box;
        text-align: center;
      }

      /* Style your items */
      .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
        background: grey;
        margin-bottom: 30px;
      }
    }

    .drama-wrap {
      display: block;
      position: relative;
      z-index: 2;
      align-items: center;
      margin-bottom: 120px;
      text-align: center;

      .text-wrap {
        width: 100%;
        height: auto;
        background: #F4F4F4;
        padding: 40px 20px 239px;
        box-sizing: border-box;
        border-radius: 10px;

        .title {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 180%;
          color: #000000;
          margin-bottom: 20px;
          text-align: left;
        }

        .staff-wrap {
          display: flex;
          flex-wrap: wrap;
          padding-right: 0px;
          box-sizing: border-box;
          text-align: left;

          p {
            font-family: 'Noto Sans KR', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #9B9B9B;

            span {
              font-weight: 700;
              color: #9B9B9B;
              margin-right: 12px;
            }
          }
        }

        .divider {
          width: 100%;
          height: 1px;
          background: #555555;
          margin-top: 18px;
          margin-bottom: 32px;
        }

        .content {
          font-family: 'Noto Sans KR', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #333333;
          text-align: left;
        }
      }

      img {
        width: calc(100% - 40px);
        height: auto;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        margin-top: -150px;
      }
    }

    .drama-wrap:last-child {
      margin-bottom: 0px;
    }
  }
}